import axios from 'axios';

const BASE_URL = 'https://api.digitalocean.com/v2/droplets';

export const getDroplets = async (token: string): Promise<any> => {
    try {
        // bearer token
        // const token: string = 'dop_v1_61b9e2dfcad928d1f3089e52b7f7b15c3c72441ea4f68ec06a8b79422a641eba';
        // const token: string = authToken;
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const result = await axios.get(BASE_URL, { headers });
        return result?.data;
    } catch (error) {
        throw new Error(error);
    }
}

export const dropletsCount = (): number | undefined => {
    try {
        return 8;
    } catch (error) {
        throw new Error(error);
    }
}