import Logo from '@/layouts/components/logo';
import Header from '@/pages/Header/header';
import routes from '@/routes';
import ApiService from '@/services/api.service';
import JwtService from '@/services/jwt.service';
import { ConsoleSqlOutlined, FormOutlined } from '@ant-design/icons';
import { Layout, Menu, MenuProps } from 'antd';
import React, { useEffect } from 'react';
import { matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom';
const { Content, Sider } = Layout;

const UserLayout = () => {
  const location = useLocation();

  const navigate = useNavigate();

  let userInfo = JSON.parse(`${JwtService.getUserInfo()}`);
  if (!userInfo) userInfo = {};

  useEffect(() => {
    if (!userInfo.username) {
      navigate('/');
    } else {
      ApiService.setAxiosGlobal(`${JwtService.getToken()}`);
    }
  });

  // tìm menu item đang chọn từ pathname khớp với routes, sài cứng routes[1]
  const matchPaths =
    routes[1]?.children?.filter((r) => {
      const routePath = r.index ? '/' : r.path ?? '/';
      return matchPath(routePath, location.pathname);
    }) || [];

  let matchMenuKeys: string[] = [];
  if (matchPaths.length > 0) {
    if (matchPaths[0].index) matchMenuKeys = ['/'];
    else if (matchPaths[0].path) matchMenuKeys = [matchPaths[0].path];
  }

  const menuItems: MenuProps['items'] = [
    {
      key: '/',
      icon: <FormOutlined />,
      label: 'Digital Oceans',
      onClick: () => navigate('/admin'),
    },
    {
      key: '/themes-and-plugins',
      icon: <ConsoleSqlOutlined />,
      label: `Themes and Plugins`,
      onClick: () => navigate('themes-and-plugins'),
    },
    {
      key: 'settings',
      icon: <FormOutlined />,
      label: `Settings`,
      onClick: () => navigate('/admin/settings'),
    },

  ];

  return (
    <>
      <Layout>
        <Sider
          theme="light"
          style={{
            overflow: 'hidden',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <Logo />
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={['menuHome']}
            items={menuItems}
            selectedKeys={matchMenuKeys}
            style={{
              height: 'calc(100vh - 64px)',
              overflowX: 'hidden',
              overflowY: 'auto',
              backgroundColor: '#96D2F9',
            }}
          />
        </Sider>
      </Layout>
      <Layout style={{ marginLeft: 200, overflow: 'hidden' }}>
        <Header />
        <Content
          style={{
            marginTop: 72,
            marginLeft: 8,
            width: 'calc(100vw - 216px)',
            height: 'calc(100vh - 80px)',
            overflow: 'auto',
          }}
        >
          <div className="layout-content">
            <Outlet />
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default UserLayout;
