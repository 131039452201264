import React, { useState, useEffect } from 'react';
import { Space, Table, Button, Modal, Spin ,message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import ApiService from '@/services/api.service';
import { WordpressSite } from '@/types';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const Wordpress = () => {
    const [listWordpress, setListWordpress] = useState<WordpressSite[]>([]);
    const [loadingData, setLoadingData] = useState(true);
    const fetchData = async () => {
        setLoadingData(true);
        await ApiService.get('/Domains/getListWordpressInstances').then(res => setListWordpress(res.data.result));
        setLoadingData(false);
    };
    useEffect(() => {
    fetchData().catch(console.error);
    }, []); 

    const { confirm } = Modal;
    const handleDelete = (record) => {
        confirm({
          title: 'Are you sure delete this wordpress site?',
          icon: <ExclamationCircleOutlined />,
          content: 'You cannot undo this action',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          async onOk() {
            try{
                await ApiService.post('/Domains/removeWordpress',{id : record.id.toString()}).then(res => {
                  console.log('delete wordpress site ok ',res);
                  fetchData().then(res => message.success('Wordpress site is deleted!'));
                 
                });
  
              }catch(err){
                console.log(err);
              }
          },
          onCancel() {
          },
        });
      };
    const columns: ColumnsType<WordpressSite> = [
    {
        title: 'Instance Id',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'siteUrl',
        dataIndex: 'siteUrl',
        key: 'siteUrl',
        render: text => <a>{text}</a>,
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Version',
        dataIndex: 'version',
        key: 'version',
    },
    // {
    //   title: 'Main Domain Id',
    //   dataIndex: 'mainDomainId',
    //   key: 'mainDomainId',
    // },
    {
        title: 'Full Path',
        dataIndex: 'fullPath',
        key: 'fullPath',
    },
    {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
        <Space size="middle">
            <Button danger onClick={() => handleDelete(record)}>Delete</Button>
        </Space>
        ),
    },
    ];

    return (
        <Spin tip="Loading..." size="large"  spinning={loadingData}>
            <Table 
                rowKey="id"
                columns={columns} 
                dataSource={listWordpress} 
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
                rowClassName="editable-row"
                />
        </Spin>
        );
};
export default Wordpress;