import axios from 'axios';
import { Godaddy, Setting } from '../types';

type Filter = {
    skip?: number;
    limit?: number;
    where?: Record<string, string | number | any>;
}

export const getList = async (filter: Filter = {}) => {
    try {
        const { data } = await axios.get('/Settings', {
            params: {
                filter,
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getGodadyList = async (filter: Filter = {}) => {
    const result = await getList(filter);
    const datas: Godaddy[] = result.map(item => {
        const temp = JSON.parse(item.value || '{}');
        return {
            id: item.id,
            endpoint: temp.endpoint,
            type: temp.type,
            key: temp.key,
            secretKey: temp.secretKey,
            name: temp.name,
        }
    })
    return datas;
}

export const getCount = async (filter: Filter) => {
    try {
        const { data } = await axios.get('/Settings/count', {
            params: {
                where: filter.where,
            },
        });
        return data?.count || 0;
    } catch (error) {
        throw new Error(error);
    }
};

export const findOne = async (filter: Filter = {}) => {
    try {
        const { data } = await axios.get('/Settings/findOne', {
            params: {
                filter,
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const updateSetting = async (input: Setting) => {
    try {
        const { data } = await axios.patch('/Settings', input);
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const postSetting = async (input: Setting) => {
    try {
        const { data } = await axios.post('/Settings', input);
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const deleteSettings = async (ids: number[]) => {
    try {
        await Promise.all(ids.map((id: number) => axios.delete(`/Settings/${id}`)));
    } catch (error) {
        throw new Error(error);
    }
};
