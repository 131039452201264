import type { RadioChangeEvent } from 'antd';
import { Input, Modal, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { Email } from './services/email-services';

type Prop = {
    showModal: boolean;
    showModalEvent: Function;
    emailInfo: Email;
    emailInfoEvent: Function;
};

const CreateEmailModal = (props: Prop) => {
    const [emailInfo, setEmailInfo] = useState<Email | Record<string, any>>({})

    const handleOK = () => {
        props.emailInfoEvent(emailInfo);
        props.showModalEvent(false);
    }

    const handleCancel = () => {
        props.showModalEvent(false)
    }

    const onStatusChange = (e: RadioChangeEvent) => {
        setEmailInfo({
            ...emailInfo,
            status: e.target.value,
        })
    };

    useEffect(() => {
        if (props.emailInfo) {
            setEmailInfo(props.emailInfo);
        }
    }, [props.emailInfo]);

    return (
        <Modal
            title="Create New"
            getContainer={false}
            open={props.showModal}
            onOk={handleOK}
            onCancel={handleCancel}
        >
            <p>NAME: (*)</p>
            <Input value={emailInfo?.name}
                onChange={e => setEmailInfo({ ...emailInfo, name: e.target.value })}
            />

            <p>Type: </p>
            <Input value={emailInfo.type}
                onChange={e => setEmailInfo({ ...emailInfo, type: e.target.value })}
            />

            <p>Value</p>
            <Input value={emailInfo.value}
                onChange={e => setEmailInfo({ ...emailInfo, value: e.target.value })}
            />

            <p>Status</p>
            <Radio.Group onChange={onStatusChange} value={emailInfo.status}>
                <Radio value="ACTIVE">ACTIVE</Radio>
                <Radio value="DISACTIVE">DISACTIVE</Radio>
            </Radio.Group>

        </Modal>
    )
}

export default CreateEmailModal;
