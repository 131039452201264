import axios from 'axios';
import { Filter } from '@/types';

export type Email = {
    name: string,
    type: string,
    value: string,
    date: Date;
    status: string
}

export const getEmails = async (filter: Filter = {}) => {
    try {
        const { data } = await axios.get('/Emails', {
            params: {
                filter,
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getEmailsCount = async (filter: Filter) => {
    try {
        const { data } = await axios.get('/Emails/count', {
            params: {
                where: filter.where,
            },
        });
        return data?.count || 0;
    } catch (error) {
        throw new Error(error);
    }
};

export const findOne = async (filter: Filter = {}) => {
    try {
        const { data } = await axios.get('/Emails/findOne', {
            params: {
                filter,
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const updateEmail = async (input: Email) => {
    try {
        const { data } = await axios.patch('/Emails', input);
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const postEmail = async (input: Email) => {
    try {
        const { data } = await axios.post('/Emails', input);
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const deleteEmails = async (ids: number[]) => {
    try {
        await Promise.all(ids.map((id: number) => axios.delete(`/Emails/${id}`)));
    } catch (error) {
        throw new Error(error);
    }
};
