import React, { useState, useEffect } from 'react';
import { Button, Input, Modal } from 'antd';
import { Godaddy } from '../types';

type Prop = {
    showModal: boolean;
    showModalEvent: Function;
    godaddyInfo: (Godaddy & { id?: number | undefined; }) | any;
};

const SettingGoDaddyCreateModal = (props: Prop) => {
    // state
    const [godaddyInfo, setgodaddyInfo] = useState<Godaddy>({ type: '', endpoint: '', key: '', secretKey: '', name: '' });

    // methods
    const handleOK = () => {
        props.showModalEvent(false, { action: 'OK', data: godaddyInfo });
    }

    const handleCancel = () => {
        props.showModalEvent(false, { action: 'CANCEL' })
    }

    useEffect(() => {
        if (props.godaddyInfo) {
            setgodaddyInfo(props.godaddyInfo);
        }
    }, [props.godaddyInfo])

    return (
        <Modal
            title={godaddyInfo?.id ? "Edit Go Daddy" : "Create New Go Daddy"}
            getContainer={false}
            open={props.showModal}
            onOk={handleOK}
            onCancel={handleCancel}
        >
            <p>Name :</p>
            <Input
                value={godaddyInfo?.name}
                onChange={e => godaddyInfo && setgodaddyInfo({ ...godaddyInfo, name: e.target.value })}
            />

            <p>Endpoint : (*)</p>
            <Input
                value={godaddyInfo?.endpoint}
                onChange={e => godaddyInfo && setgodaddyInfo({ ...godaddyInfo, endpoint: e.target.value })}
            />

            <p>Key : </p>
            <Input
                value={godaddyInfo?.key}
                onChange={e => godaddyInfo && setgodaddyInfo({ ...godaddyInfo, key: e.target.value })}
            />

            <p>Secret key: (*)</p>
            <Input
                value={godaddyInfo?.secretKey}
                onChange={e => godaddyInfo && setgodaddyInfo({ ...godaddyInfo, secretKey: e.target.value })}
            />

            <p>Type: (*)</p>
            <Input
                value={godaddyInfo?.type}
                onChange={e => godaddyInfo && setgodaddyInfo({ ...godaddyInfo, type: e.target.value })}
            />
        </Modal>
    )
}

export default SettingGoDaddyCreateModal;
