import React, {useState } from 'react';
import { Form, Input, Menu, Dropdown, message, Modal } from 'antd';
import type { MenuProps } from 'antd';
import { UserOutlined, LogoutOutlined, KeyOutlined, EyeTwoTone, EyeInvisibleOutlined, InfoCircleFilled} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import  AuthenService  from '@/services/authen.service';
import JwtService from '@/services/jwt.service';
import ApiService from '@/services/api.service';

const Logout = () => {
  const navigate = useNavigate();


  const handleMenuClick: MenuProps['onClick'] = ({ key }: { key: string }) => {
    if (key === 'LOGOUT') {
      AuthenService.logout();
      navigate('/');
      message.success({ content: 'You have been successfully logged out!',key, duration: 2});
    }
    if (key === 'INFO') {
      showModal();
    }
    if (key === 'PASS') {
      showModal2();
    }
  };

  let oldPassword: string = '';
  let newPassword: string = '';
  let confirmPassword: string = '';
  let userInfo = JSON.parse(`${JwtService.getUserInfo()}`);
  if(!userInfo) {
    userInfo = {username:''};
    navigate('/');
  }
  const menuItems = [
    {
      label: 'Information',
      key: 'INFO',
      icon: <UserOutlined />,
    },
    {
      label: 'Change Password',
      key: 'PASS',
      icon: <KeyOutlined />,
    },
    {
      label: 'Logout',
      key: 'LOGOUT',
      icon: <LogoutOutlined />,
    },
  ]

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    let key = 'update_info';
    if(!userInfo.email){
      message.error({ content: 'Please input your email!',key,duration:3});
      return;
    }
    message.loading({ content: 'Loading - Please wait...',key});
    ApiService.patch('/AppUsers',userInfo.userId,userInfo).then(res =>{
      JwtService.saveUserInfo(res.data);
      message.success({ content: 'Your information are updated!',key, duration: 3});
      setIsModalVisible(false);
    }).catch(err =>{
      message.error({ content: 'The email you have entered is invalid!',key, duration:4});
    });


  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const showModal2 = () => {
    setIsModalVisible2(true);
  };
  const handleOk2 = () => {
    let key = 'update_password'
    message.loading({ content: 'Loading - Please wait...',key});
    if(!oldPassword) {
      message.error({ content: 'Please input your old password!', key, duration: 3});
      return;
    }
    if(!newPassword) {
      message.error({ content: 'Please input your new password!', key, duration: 3});
      return;
    }
    if(!confirmPassword) {
      message.error({ content: 'Please confirm your password!', key, duration: 3});
      return;
    }
    if(newPassword !== confirmPassword){
      message.error({ content: 'The two passwords that you entered do not match!', key, duration: 3});
      return;
    }
    ApiService.post('/AppUsers/login',{username: userInfo.username, password: oldPassword}).then(res => {
      if(res.data.id){
        ApiService.patch('/AppUsers',userInfo.userId,{password: newPassword}).then(res=>{
          message.success({ content: 'Your password has been changed successfully!', key, duration: 3});
          setIsModalVisible2(false);
        });
      }
    }).catch(err => {
      message.error({ content: 'The old password you have entered is incorrect!', key, duration: 3});
      return;
    })
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
  };

  return (
    <div>
      <div>
        <Dropdown.Button
          onClick={()=>showModal()}
          menu={{items: menuItems, onClick: handleMenuClick}}
          placement="bottom"
          icon={<UserOutlined />}
        >
          <span> {userInfo.username}</span>
        </Dropdown.Button>
      </div>
      <div>
        <Modal title="User Information" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <Form
            initialValues={{["username"]: userInfo.username,["email"]: userInfo.email}}
          >
          <Form.Item name="username" label="Username" rules={[{ required: true }]} >
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please input your email!'  }]} >
            <Input value={userInfo.email} onChange={e=> userInfo.email = e.target.value}/>
          </Form.Item>
        </Form>
        </Modal>
      </div>
      <div>
        <Modal title="Change Password" open={isModalVisible2} onOk={handleOk2} onCancel={handleCancel2}>
          <Form >
          <Form.Item
            name="old_password"
            label="Old pasword"
            hasFeedback
            rules={[
              { required: true, message: 'Please input your old password!' }
              ]}
          >
            <Input.Password placeholder="password" value={oldPassword} onChange={e=> oldPassword = e.target.value}/>
          </Form.Item>
          <Form.Item
            name="new_password"
            label="New password"
            rules={[
              {
                required: true,
                message: 'Please input your new password!'
              }
              ]}
            hasFeedback
          >
            <Input.Password placeholder="password" value={newPassword} onChange={e=> newPassword = e.target.value}/>
          </Form.Item>

          <Form.Item
            name="confirm_password"
            label="Confirm password"
            dependencies={['new_password']}
            hasFeedback
            rules={[
                {required: true, message: 'Please confirm your password!'},
                ({getFieldValue}) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('new_password') === value)
                            return Promise.resolve()
                        else
                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    }
                })
            ]}
          >
            <Input.Password placeholder="password"  value={confirmPassword} onChange={e=> confirmPassword = e.target.value}/>
          </Form.Item>
        </Form>
        </Modal>
      </div>
    </div>
  );
};

export default Logout;
