import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Modal, Space, Spin, Table } from 'antd';
import ApiService from '@/services/api.service';
import { useLocation } from 'react-router-dom';
import { DomainPlesk, BackupTemplate } from '@/types';
import { ColumnsType } from 'antd/lib/table';
import { DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import EnableSshTutorialModal from './components/enable-ssh-tutorial-modal';
const { confirm } = Modal;

const BackupTemplateDetails = (
    {
      domain,
      getBackupTemplateList,
      backupTemplateList,
      loadingBackupTemplateList,
    }: {
      domain : DomainPlesk,
      getBackupTemplateList : (domainName: string, filter?: object) => Promise<void>,
      backupTemplateList : BackupTemplate[],
      loadingBackupTemplateList : boolean,
    }
) => {

    const { state } = useLocation();
    const [showModalUpdateBackupTemplate, setShowModalUpdateBackupTemplate] = useState<boolean>(false);
    const [showModalTutorial, setShowModalTutorial] = useState<boolean>(false);
    const [editBackupTemplateItem, setEditBackupTemplateItem] = useState<BackupTemplate>();
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    useEffect(() => {
        getBackupTemplateList(domain.name,{where:{backupFromDomain: domain.name}});
    },[]);

    const columnsBackupTemplate: ColumnsType<BackupTemplate> = [
      {
        title: 'File ID',
        dataIndex: 'fileId',
        key: 'fileId',
      },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: (_,record) =>{
            let url = 'https://hmedia.pml-tech.com/file/'+ record.fileId;
            return (<>
                    <DownloadOutlined />  <a href={url} target="_blank">{record.name} </a>
                    </>
            )
          } 
          
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
        },
    
        {
            title: 'Action',
            key: 'action',
            render: (_,record) => (
              <>
                <Space>
                  {/* <Button onClick={() => handleEditBackupTemplate(record)}>Edit</Button> */}
                  <Button onClick={() => handleShowModalUpdate(record)}>Edit</Button>
                </Space>
              </>
            ),
          }
        
      ];

      const handleShowModalTutorial = () => {
        setShowModalTutorial(true);
      }

      const handleShowModalUpdate = (item : BackupTemplate) => {
        setEditBackupTemplateItem(item);
        setName(item.name);
        setDescription(item.description);
        setShowModalUpdateBackupTemplate(true);
      }


      const handleUpdateBackupTemplate = async () => {
        setShowModalUpdateBackupTemplate(false);
            let item = editBackupTemplateItem;
            item.name = name;
            item.description = description;
            await ApiService.patch('/BackupTemplates/', item?.id, item).then(async res => {
              console.log(res.data);
              await getBackupTemplateList(domain.name, {where:{backupFromDomain: domain.name}} );
            })
      }
      const handleCancelShowModalTutorial = () => {
          setShowModalTutorial(false);
      }
      const handleCancelUpdateBackupTemplate = () => {
          setShowModalUpdateBackupTemplate(false);
      }
    return (
        <div>
            <Button type='default' onClick={() => getBackupTemplateList(domain.name,{where:{backupFromDomain: domain.name}})}>Refresh</Button>

            <Spin tip="Loading..." size="large"  spinning={loadingBackupTemplateList}>
                <Table
                    rowKey="name"
                    columns={columnsBackupTemplate}
                    dataSource={backupTemplateList}
                />
                <div style={{'color': 'red','fontStyle':'italic'}}>
                  Note: You need to setup Firewall, configure Secure FTP and enable SSH access in Plesk server before backup and restore templates. 
                  <a style={{'color':'blue'}} onClick={handleShowModalTutorial}> Read tutorial!</a>
                </div>
            </Spin>
          <Modal
              destroyOnClose
              open={showModalUpdateBackupTemplate}
              title="Update Backup Template"
              onOk={handleUpdateBackupTemplate}
              onCancel={handleCancelUpdateBackupTemplate}
              width={800}
              style={{ top: 20 }}
              >
            <Form>
              <Form.Item label="Name" rules={[{required: true}]}>
						   <Input value={name} onChange={e => setName(e.target.value)} />
              </Form.Item>
              <Form.Item label="Description" rules={[{required: true}]}>
						   <Input value={description} onChange={e => setDescription(e.target.value)} />
              </Form.Item>
            </Form>
        </Modal>
       <EnableSshTutorialModal 
         showModalTutorial = {showModalTutorial}
         handleCancelShowModalTutorial = {handleCancelShowModalTutorial}
       />
            
        </div>
    );
}

export default BackupTemplateDetails;