import { Table } from 'antd';
import format from 'date-fns/format';
import React from 'react';

type LogDetail = {
    status: string;
    value: {
        status: number;
        domain: string;
    }
};

type Prop = {
    logsCount: number;
    logList: {
        date: Date,
        value: LogDetail[],
    }[];
};

const columns = [
    { title: 'Date', dataIndex: 'date', key: 'date', render: (value) => <p>{format(new Date(value), "yyyy-MM-dd kk:mm:ss")}</p>, },
    { title: 'Type', dataIndex: 'type', key: 'type' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
];

type DetailProp = {
    logs: LogDetail[];
}
const DetailLog = ({ logs = [] }: DetailProp) => {
    return (
        <div>
            <p>Detail</p>
            <p>Total: { logs.length }</p>
            {
                logs.map((log, index) => (
                    <div key={`${index}`}>
                        <p>{index + 1}___ {log.value.domain} ____ {log.value.status}</p>
                    </div>
                ))
            }
        </div>
    );
};

const StatusLogs = ({ logList = [] }: Prop) => {
    return (
        <div>
            <p>StatusLogs</p>
            <Table
                rowKey={(record) => (record as Record<string, any>).key}
                columns={columns}
                expandable={{
                    expandedRowRender: record => <DetailLog logs={record.value} />,
                }}
                dataSource={logList}
            />
        </div>
    )
}

export default StatusLogs;