import ApiService from '@/services/api.service';
import { DomainPlesk } from '@/types';
import { Tabs, TabsProps } from 'antd';
import React, { useEffect, useState } from 'react';
import StatusConfigs from './Status/StatusConfigs';
import StatusLogs from './Status/StatusLogs';
import StatusSendMails from './Status/StatusSendMails';
import { getListDomainChecked, getLogs, getLogsCount, getStatusCodeCheck, saveListDomainChecked, saveStatusCodeCheck } from './Status/services/status-services';
import { useQuery } from 'react-query';
import { deleteEmails, Email, getEmails, getEmailsCount, postEmail, updateEmail } from './Status/services/email-services';

type Prop = {
    state: {
        apiEndpoint: string;
        authenInfo: string;
    } | any;
}

const PleskStatusModal = ({ state }: Prop) => {

    const [listDomains, setListDomains] = useState<DomainPlesk[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [loadingType, setLoadingType] = useState<{ type: string, value: boolean }>();

    // hanle logs
    const { data: logsCount } = useQuery('logsCount', () => getLogsCount({ order: 'DESC date' }));
    const [pageLog, setPageLog] = useState<number>(1);
    const [pageLogSize, setPageLogSize] = useState<number>(10);
    const {
        isLoading,
        isError,
        error,
        refetch,
        data: logList,
    } = useQuery(
        ['logList', { skip: (pageLog - 1) * pageLogSize, limit: pageLogSize }],
        () => getLogs({ order: "date DESC", where: { type: "domains-checked", name: "status", } })
    );

    // handle emails
    const { data: emailsCount } = useQuery('emailsCount', () => getEmailsCount({ order: 'DESC date' }));
    const [pageEmail, setPageEmail] = useState<number>(1);
    const [pageEmailSize, setPageEmailSize] = useState<number>(10);
    const {
        isLoading: isEmailLoading,
        isError: isEmailError,
        error: emailError,
        refetch: emailRefetch,
        data: emailList,
    } = useQuery(
        ['emailList', { skip: (pageLog - 1) * pageLogSize, limit: pageLogSize }],
        () => getEmails({ where: { status: "ACTIVE" } })
    );

    const getListDomain = async () => {
        try {
            const { data } = await ApiService.getWithParams('/Domains/getPleskDomains', {
                infoEncoded: state,
            });
            setListDomains(data.result)
        } catch (error) {

        }
    }

    const saveChangesEvt = async (data: any) => {
        if (data.type === 'CHANGE') {
            setSelectedRowKeys(data.rowKeys);
            return;
        }
        if (data.type === 'SAVE') {
            const domains: { id: number, name: string }[] = [];
            listDomains.forEach((domain: DomainPlesk) => {
                if (selectedRowKeys.includes(domain.name)) {
                    domains.push({
                        id: domain.id,
                        name: domain.name,
                    })
                }
            })

            await saveListDomainChecked(state.vpsIP, domains);
            setLoadingType({
                type: 'configs',
                value: false,
            })
        }
    }

    const getDomainsChecked = async () => {
        if(!state.vpsIP) return;
        const result = await getListDomainChecked(state.vpsIP);
        if (!result) {
            return;
        }
        // check selected theo domain name
        const domains = result.map(domain => domain.name);
        setSelectedRowKeys(domains);
    }

    const emailInfoEvent = async (email: Email & { id?: number }) => {
        if (email.id) {
            await updateEmail(email);
        } else {
            await postEmail({ ...email, date: new Date() });
        }
        await emailRefetch();
    }

    const deleteEmailEvent = async (email: Email & { id?: number }) => {
        if (email.id) {
            await deleteEmails([email.id]);
            await emailRefetch();
        }
    }

    // checkboxs status code website list
    const { data: statusCodesCheck } = useQuery('statusCodeCheck', () => getStatusCodeCheck());
    const updateStatusCodeCheck = (data: string[]) => {
        saveStatusCodeCheck(data);
    }

    useEffect(() => {
        getListDomain().then(() => {
            getDomainsChecked();
        });
    }, []);

    const tabItems: TabsProps['items'] = [
        {
            key: '1',
            label: 'Logs',
            children:  <StatusLogs logsCount={logsCount} logList={logList} />
        },
        {
            key: '2',
            label: 'Send Mail',
            children: <StatusSendMails
                            emails={emailList}
                            statusCodesCheck={statusCodesCheck}
                            updateStatusCodeCheck={updateStatusCodeCheck}
                            deleteEmailEvent={deleteEmailEvent}
                            emailInfoEvent={emailInfoEvent}
                        />
        },
        {
            key: '3',
            label: 'Configs',
            children: <StatusConfigs loading={loadingType} listDomains={listDomains} selectedRowKeys={selectedRowKeys} saveChangesEvt={saveChangesEvt} />
        }
    ]
    return (
        <>
            <p>PleskInfo: {state?.apiEndpoint} </p>
            <Tabs defaultActiveKey="1" items={tabItems}  />
        </>
    )
}

export default PleskStatusModal;
