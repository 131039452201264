import React, { useState, useEffect } from 'react';
import { Space, Table, Button, Modal, Spin, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import ApiService from '@/services/api.service';
import { DomainPlesk } from '@/types';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const WebsitesAndDomains = () => {
    const [listDomains, setListDomains] = useState<DomainPlesk[]>([]);
    const [loadingData, setLoadingData] = useState(true);
    const fetchData = async () => {
        setLoadingData(true);
        await ApiService.get('/Domains/getListAllDomains').then(res => setListDomains(res.data.result));
        setLoadingData(false);
      };
      useEffect(() => {
        fetchData().catch(console.error);
      }, []); 
     
      const { confirm } = Modal;
      const handleDelete = (record) => {
        confirm({
          title: 'Are you sure delete this domain?',
          icon: <ExclamationCircleOutlined />,
          content: 'Delete this domain also delete all installed wordpress sites on this domain. You cannot undo this action',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          async onOk() {
            try{
              await ApiService.post('/Domains/deleteDomain',{id : record.id}).then(res => {
                console.log('delete domain ok ',res);
                fetchData().then(res =>  message.success('Domain is deleted!'));
              });

            }catch(err){
              console.log(err);
            }
          },
          onCancel() {
          },
        });
      };
      const columns: ColumnsType<DomainPlesk> = [
        {
          title: 'Domain Id',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: text => <a>{text}</a>,
        },
        {
          title: 'Hosting Type',
          dataIndex: 'hosting_type',
          key: 'hosting_type',
        },
        {
          title: 'Created',
          dataIndex: 'created',
          key: 'created',
        },
        {
          title: 'Base Domain Id',
          dataIndex: 'base_domain_id',
          key: 'base_domain_id',
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
          <Space size="middle">
              <Button danger onClick={() => handleDelete(record)}>Delete</Button>
          </Space>
          ),
      },
      ];

    return (
      <Spin tip="Loading..." size="large"  spinning={loadingData}>
          <Table 
              rowKey="id"
              columns={columns} 
              dataSource={listDomains} 
              pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
              rowClassName="editable-row"
              />
        </Spin>
        );
}
export default WebsitesAndDomains;