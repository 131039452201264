import ApiService from '@/services/api.service';
import { DomainPlesk, EmailAccount } from '@/types';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, message, Modal, Row, Spin, Tabs } from 'antd';
import React , { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
const { TextArea } = Input;
const UpdateEmailAddressModal = (
    {
        editEmail,
        showModalUpdateEmailAddress,
        setShowModalUpdateEmailAddress,
        getEmailAccountList

    } : {
        editEmail : EmailAccount,
        showModalUpdateEmailAddress : boolean,
        setShowModalUpdateEmailAddress : Function,
        getEmailAccountList : Function

    }
) => {
    const { state } = useLocation();
    const checkPasswordStrength = (password: string ='') => {
        let strength: number = 0;
        if (password.match(/[a-z]+/)) {
          strength += 1;
        }
        if (password.match(/[A-Z]+/)) {
          strength += 1;
        }
        if (password.match(/[0-9]+/)) {
          strength += 1;
        }
        if (password.match(/[$@#&!]+/)) {
          strength += 1;
      
        }
      
        if(strength<4 || password.length<9){
            return false;
        } 
        return true;
        
    }
    const handleUpdateEmailAddress = async () => {
        if(!emailAddress){
            setStatusEmailAddress('Please input Email address.')
            return;
        }
        setStatusEmailAddress('');
        if(emailPassword && !checkPasswordStrength(emailPassword)){
            const status = `Minimum length of password is 9. 
            At least 1 letter between lowercase [ a - z ].
            At least 1 letter between uppercase [ A - Z ].
            At least 1 number between [ 0 - 9 ].
            At least 1 special character from [ $@#&! ].
            `;
            setStatusPassword(status);
            return;
        }
        if(emailPassword && emailPassword!==confirmEmailPassword){
            setStatusPassword('Password and confirm password does not match.');
            return;
        }
        setStatusPassword('');
        confirm({
            title: 'Are you sure to update email address?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const res = await ApiService.post('/Domains/updateMail', {
                        newMail: oldEmailAddress + '@' + emailAccountInfo.domain, 
                        newMailName: emailAddress!==oldEmailAddress?emailAddress:'',
                        newPassword: emailPassword,
                        enableMailbox: enableMailbox,
                        mailboxSize: '',
                        enableForwarding: enableForwarding,
                        listForwardingAddresses: listForwardingAddresses,
                        listForwardingAddressesOld: listForwardingAddressesOld,
                        enableLogInToPlesk: enableLogInToPlesk,
                        listAliases: listAliases,
                        listAliasesOld: listAliasesOld,
                        infoEncoded : state});
                    await getEmailAccountList();
                    setShowModalUpdateEmailAddress(false);
                    message.success('Email address has been updated!');
                } catch (err) {
                     message.error(err, 10);
                     console.error(err);
                    setShowModalUpdateEmailAddress(false);

                 }
            },
            onCancel() {
            },
          });

       
    }
    const handleCancelUpdateEmailAddress = () => {
        setShowModalUpdateEmailAddress(false);
    }
    const { confirm } = Modal;
    const { TabPane } = Tabs;    const [emailAddress, setEmailAddress] = useState<string>();
    const [statusPassword, setStatusPassword] = useState<string>();
    const [statusEmailAddress, setStatusEmailAddress] = useState<string>();
    const [oldEmailAddress, setOldEmailAddress] = useState<string>();
    const [emailPassword, setEmailPassword] = useState<string>();
    const [confirmEmailPassword, setConfirmEmailPassword] = useState<string>();
    const [enableMailbox, setEnableMailbox] = useState<boolean>(true);
    const [enableLogInToPlesk, setEnableLogInToPlesk] = useState<boolean>(false);
    const [enableForwarding, setEnableForwarding] = useState<boolean>(false);
    const [listForwardingAddresses, setListForwardingAddresses] = useState<string>();
    const [listForwardingAddressesOld, setListForwardingAddressesOld] = useState<string>();
    const [listAliases, setListAliases] = useState<string>();
    const [listAliasesOld, setListAliasesOld] = useState<string>();
    const [loadingEmailAccountInfo, setLoadingEmailAccountInfo] = useState<boolean>(false);
    const [emailAccountInfo, setEmailAccountInfo] = useState<any>();
    const getEmailAccountInfo = async () => {
        try {
            setLoadingEmailAccountInfo(true);
            const res = await ApiService.post('/Domains/getEmailAccountInfo', {
                mail: editEmail.name, 
                infoEncoded : state});
                console.log(res);
            let mailInfo = res.data.result;
            setEmailAccountInfo(mailInfo);
            setEnableMailbox(mailInfo.mailbox==="true"?true:false)
            setEmailAddress(mailInfo.mailname);
            setOldEmailAddress(mailInfo.mailname);
            setEmailPassword('');
            setStatusPassword('');
            setConfirmEmailPassword('');
            setEnableForwarding(mailInfo.mailgroup==="true"?true:false);
            setListForwardingAddresses(mailInfo.groupmember?mailInfo.groupmember.replace(/ /gi,','):'');
            setListForwardingAddressesOld(mailInfo.groupmember?mailInfo.groupmember.replace(/ /gi,','):'');
            setListAliases(mailInfo.alias);
            setListAliasesOld(mailInfo.alias);
            setLoadingEmailAccountInfo(false);

        } catch (err) {
             message.error(err, 10);
             console.error(err);
            setShowModalUpdateEmailAddress(false);
            setLoadingEmailAccountInfo(false);

         }
    }
    useEffect(()=>{
        getEmailAccountInfo();
    },[editEmail])

    return (
        <Modal
        destroyOnClose
        open={showModalUpdateEmailAddress}
        title="Update Email Address"
        onOk={handleUpdateEmailAddress}
        onCancel={handleCancelUpdateEmailAddress}
        width={800}
        style={{ top: 20 }}
        >
            <Spin tip="Loading..." size="large"  spinning={loadingEmailAccountInfo}>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="General" key="1">
                        <Form>
                            
                                <Row>
                                    <Col span={8}><Form.Item label="Email address" rules={[{ required: true }]} /></Col>
                                    <Col span={8}>
                                        <Input status={emailAddress?'':"error"} value={emailAddress} onChange={e => setEmailAddress(e.target.value)} />
                                        <span style={{color: 'red',padding:'5px'}}> {statusEmailAddress}</span>
                                        </Col>
                                    <Col span={8}> &nbsp;@{emailAccountInfo?.domain}</Col>
                                </Row>
                                
                            
                                
                                <Row>
                                    <Col span={8}><Form.Item  label="Password" rules={[{ required: true }]} /></Col>
                                    <Col span={8}>
                                        <Input.Password status={statusPassword?'error':''} value={emailPassword} onChange={e => setEmailPassword(e.target.value)}/>
                                        <span style={{color: 'red',padding:'5px'}}> {statusPassword}</span>
                                        </Col>
                                    <Col span={8}></Col>
                                </Row>
                                <Row>
                                    <Col span={8}><Form.Item  label="Confirm password" rules={[{ required: true }]} /></Col>
                                    <Col span={8}><Input.Password status={statusPassword?'error':''} value={confirmEmailPassword} onChange={e => setConfirmEmailPassword(e.target.value)} /></Col>
                                    <Col span={8}></Col>
                                </Row>
                                <Row>
                                    <Col span={8}>
                                        {/* <Checkbox checked={enableLogInToPlesk} onChange={(e) => setEnableLogInToPlesk(e.target.checked)}>Can be used to log in to Plesk</Checkbox> */}
                                    </Col>
                                    <Col span={8}></Col>
                                    <Col span={8}></Col>
                                </Row>
                                <Row>
                                    <Col span={8}>
                                    <Checkbox checked={enableMailbox} onChange={(e) => setEnableMailbox(e.target.checked)}>Mail Box (Default size)</Checkbox>
                                    </Col>
                                    <Col span={8}></Col>
                                    <Col span={8}></Col>
                                </Row>
                            
                        </Form>
                    </TabPane>
                    <TabPane tab="Forwarding" key="2">
                        <p>Set up forwarding of email messages to one or more email addresses.</p>
                        <Form>
                          
                            <Checkbox checked={enableForwarding} onChange={(e) => setEnableForwarding(e.target.checked)}>Switch on mail forwarding</Checkbox>
                            
                            <Row>
                            <Col span={12}>
                                <Form.Item  label="Forward incoming messages to the following email address "/>
                            </Col>
                            <Col span={12}>
                            <TextArea 
                                    disabled={!enableForwarding}
                                    rows={5} 
                                    value={listForwardingAddresses} 
                                    onChange={e => setListForwardingAddresses(e.target.value)}></TextArea>
                            </Col>
                            </Row>
                            
                               
                           
                        </Form>
                        <Row>
                                    <i>Note: Specify email addresses. Separate addresses with commas.</i>
                            </Row>
                    </TabPane>
                    <TabPane tab="Email Aliases" key="3">
                        <p>Email aliases are alternative email addresses associated with a primary email address. All mail sent to email aliases will be received by the primary email address.</p>
                        <Form>
                            <Row>
                                <Col span={6}><Form.Item name="listAliases" label="Email aliases" rules={[{ required: false }]} /></Col>
                                <Col span={12}><TextArea rows={5} value={listAliases} onChange={e => setListAliases(e.target.value)} ></TextArea></Col>
                                
                            
                            </Row>
                            <Row>
                                    <i>Note: Specify email aliases. Separate aliases with commas. Don't need to type <strong>@{emailAccountInfo?.domain}</strong>. E.g: Alias1, Alias2, Alias3</i>
                            </Row>
                        
                        </Form>
                    </TabPane>
                </Tabs>
            </Spin>

        </Modal>
    );
}
export default UpdateEmailAddressModal;
