import { DomainPlesk } from '@/types';
import { Button, Select, Table, Spin } from 'antd';
import React, { useState, useEffect } from 'react';

type Prop = {
    loading?: {type: string, value: boolean};
    listDomains: DomainPlesk[];
    selectedRowKeys: string[];
    saveChangesEvt: (data: any) => void;
}

const { Option } = Select;

const columns = [
    {
        title: 'id',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'base_domain_id',
        dataIndex: 'base_domain_id',
        key: 'base_domain_id',
    },
    {
        title: 'hosting_type',
        dataIndex: 'hosting_type',
        key: 'hosting_type',
    },
    {
        title: 'created',
        dataIndex: 'created',
        key: 'created',
    },
];

const StatusConfigs = (props: Prop) => {

    const [isLoading, setIsLoading] = useState<{type: string, value: boolean}>();

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: DomainPlesk[]) => {
            props.saveChangesEvt({ type: 'CHANGE', rowKeys: selectedRowKeys })
        },
        getCheckboxProps: (record: DomainPlesk) => ({
            name: record.name,
        }),
        selectedRowKeys: props.selectedRowKeys,
    };

    const saveChanges = () => {
        setIsLoading({type: 'configs', value: true});
        props.saveChangesEvt({
           type: 'SAVE'
        });
    }

    useEffect(() => {
        if (props.loading?.type === 'configs' && props.loading?.value !== isLoading?.value) {
            setIsLoading(props.loading);
        }
    }, [props.loading]);

    return (
        <div className='configs'>
            {/* <div className='configs__time'>
                <p>Run CronJob every</p>
                <input type="text" placeholder='Amount time' />
                <Select defaultValue="lucy" style={{ width: 120 }} >
                    <Option value="days">Days</Option>
                    <Option value="hours">Hours</Option>
                    <Option value="minutes">Minutes</Option>

                </Select>
            </div>
            <br /> */}
            <div className='configs__actions'>
                <Button type='primary' onClick={saveChanges} disabled={true}>Save changes</Button>
            </div>
            <p>Select list domain to run cronjob for check status</p>
            {/* <Spin tip="Saving..." spinning={isLoading?.value}></Spin> */}
            <p>Selected <b> { props.selectedRowKeys.length } / { props.listDomains.length }</b> total domains</p>
            {/* <p>{props.selectedRowKeys}</p> */}
            <div className='configs__table'>
                <Table
                    rowKey="name"
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                        getCheckboxProps: (record) => {
                            return {
                              disabled: true 
                            };
                          }
                    }}
                    dataSource={props.listDomains} columns={columns}
                    pagination={{ defaultPageSize: 50, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50', '100', '200', '300', '500']}}
                />;
            </div>
        </div>
    )
}

export default StatusConfigs;
