import { Button, Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';
import { Email } from '../services/email-services';
import CreateEmailModal from './CreateEmailModal';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';

type Prop = {
    emails: Email[];
    statusCodesCheck: string[] | undefined;
    deleteEmailEvent: Function;
    emailInfoEvent: Function;
    updateStatusCodeCheck: Function;
};

const StatusSendMails = (
    {
        emails = [],
        emailInfoEvent,
        deleteEmailEvent,
        updateStatusCodeCheck,
        statusCodesCheck = []
    }: Prop) => {
    const CheckboxGroup = Checkbox.Group;
    const [showCreateNewDialog, setShowCreateNewDialog] = useState<boolean>(false);
    const [emailInfo, setEmailInfo] = useState<Email | any>({});

    const showEmailModalEvent = (state: boolean) => {
        setShowCreateNewDialog(state);
    }

    const emailInfoEvt = (emailInfo: Email & { id?: number }) => {
        emailInfoEvent(emailInfo)
    }

    const updateEmail = (email: Email) => {
        setEmailInfo(email);
        setShowCreateNewDialog(true);
    }

    const deleteEmail = (email: Email) => {
        deleteEmailEvent(email);
    }

    // website status
    const plainOptions = [
        '200_OK',
        '404_NOT_FOUND',
        '301_PERMANENT_REDIRECT',
        '302_TEMPORARY_REDIRECT',
        '503_SERVICE_UNAVAILABLE',
        '500_INTERNAL_SERVER_ERROR',
    ];

    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(statusCodesCheck);

    const onChange = (list: CheckboxValueType[]) => {
        setCheckedList(list);
    };

    const changeStatusCodeChecked = (): void => {
        updateStatusCodeCheck(checkedList);
    }

    useEffect(() => {
        setCheckedList(statusCodesCheck);
    }, [statusCodesCheck])

    return (
        <div>
            <Button onClick={() => setShowCreateNewDialog(true)}>Add New Email</Button>
            <br />
            <p>List Website Status for send mail</p>
            <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
            <Button onClick={changeStatusCodeChecked}>Save Changes</Button>
            <p>List Email</p>
            {
                emails.map((email, index) => (
                    <div key={`${index}`}>
                        <hr />
                        <Button onClick={() => deleteEmail(email)}>Delete</Button>
                        <Button onClick={() => updateEmail(email)}>Update</Button>
                        <p>{index + 1}. Name: {email.name}. Type: {email.type}. Value: {email.value}. Status: {email.status}</p>
                    </div>
                ))
            }
            <CreateEmailModal
                emailInfo={emailInfo}
                showModal={showCreateNewDialog}
                emailInfoEvent={emailInfoEvt}
                showModalEvent={showEmailModalEvent}
            />
        </div>
    )
}

export default StatusSendMails;