import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Modal, Space, Spin, Table } from 'antd';
import ApiService from '@/services/api.service';
import { useLocation } from 'react-router-dom';
import { DomainPlesk, BackupTemplate } from '@/types';
import { ColumnsType } from 'antd/lib/table';
import { DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import EnableSshTutorialModal from './components/enable-ssh-tutorial-modal';
const { confirm } = Modal;

const RestoreTemplateDetails = (
    {
      domain,
      getBackupTemplateList,
      restoreTemplateList,
      loadingBackupTemplateList,
    }: {
      domain : DomainPlesk,
      getBackupTemplateList : (domainName: string, filter?: object) => Promise<void>,
      restoreTemplateList : BackupTemplate[],
      loadingBackupTemplateList : boolean,
    }
) => {

    const { state } = useLocation();
    const [showModalUpdateBackupTemplate, setShowModalUpdateBackupTemplate] = useState<boolean>(false);
    const [editBackupTemplateItem, setEditBackupTemplateItem] = useState<BackupTemplate>();
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [selectedBackupTemplates, setSelectedBackupTemplates] = useState<BackupTemplate[]>();
    const [showModalTutorial, setShowModalTutorial] = useState<boolean>(false);

    useEffect(() => {
      getBackupTemplateList(domain.name);
    },[]);

    const columnsBackupTemplate: ColumnsType<BackupTemplate> = [
      {
        title: 'File ID',
        dataIndex: 'fileId',
        key: 'fileId',
      },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: (_,record) =>{
            let url = 'https://hmedia.pml-tech.com/file/'+ record.fileId;
            return (<>
                    <DownloadOutlined />  <a href={url} target="_blank">{record.name} </a>
                    </>
            )
          } 
          
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
        }
        
      ];

      const rowSelectionBackupTemplate = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: BackupTemplate[]) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          setSelectedBackupTemplates(selectedRows);
        }
      };
      const handleRestoreFromBackupTemplate = async () => {
        if(!selectedBackupTemplates){
          message.error('Please select Backup Template');
          return ;
        }
        let stopProcess = false;
        // Step 1: Download backup template from cloud server
        message.info('Downloading backup template...!')

        const body = {
          domainName : domain.name,
          userName : domain.name + '_subftp',
          password : 'changeme1Q@123XYZ',
          path : '/',
          fileId : selectedBackupTemplates[0].fileId,
          infoEncoded: state
        }
        await ApiService.post('/Domains/createFTPSubAccountAndDownloadBackupFile',body).then(async res => {
          if(res?.data?.result?.success){
            message.success('Download backup template successfully!')
          }else{
            message.error('Download backup template failed!');
            stopProcess = true;
          }
        });
        if(stopProcess) return;
        // Step 2: Upzip backup template
        // message.info('Extracting backup template...!')

        const body2 = {
          zipFileName : 'BackupTemplate.zip',
          host : state.vpsIP,
          username : domain.name + "_ftp",
          password : 'changeme1Q@123XYZ',
        }
        await ApiService.post('/Domains/sshConnectAndUnzipBackupFile',body2).then(async res => {
          if(res?.data?.result?.success){
            message.success('Extract backup template successfully!')
          }else{
            message.error('Please Allow access to the server over SSH.');
            stopProcess = true;

          }
        });
        if(stopProcess) return;

        // Step 3: Restore database
        const body3 = {
          host : state.vpsIP,
          username : domain.name + "_ftp",
          password : 'changeme1Q@123XYZ',
        }
        let res3 = await ApiService.post('/Domains/sshConnectAndChangeWPConfigInfo',body3);
        let dbInfo = res3.data?.result;
        if(!dbInfo) return;
       const body4 = {
        dbName: dbInfo.dbName,
        dbUsername: dbInfo.dbUser,
        dbUserPassword: dbInfo.dbPassword,
        domain: domain.name,
        infoEncoded: state
       }
       let res4 = await ApiService.post('/Domains/createDatabaseWordPress',body4);
       if(!res4?.data?.result?.code){
        message.success('Create database successfully!');
       }else{
        message.error(res4?.data?.result?.stderr);
        stopProcess = true;

       }
       if(stopProcess) return;
       
       const body5 = {
        dbName: dbInfo.dbName,
        pathToDumpFile: '/var/www/vhosts/'+ domain.name + '/sqldump.sql',
        infoEncoded: state
       }
       let res5 = await ApiService.post('/Domains/restoreDatabaseWordPressFromDumpFile',body5);
        if(!res5?.data?.result?.code){
          message.success('Restore wordpress site from backup template successfully!');
        }else{
          message.error(res5?.data?.result?.stderr);

         }
      }
      const confirmRestoreFromBackupTemplate = async () => {
        if(!selectedBackupTemplates){
          message.warning('Please select a Backup Template item');
          return;
        }
        confirm({
          title:      'Are you sure to restore this wordpress website from selected Backup Template?',
          icon:       <ExclamationCircleOutlined/>,
          content:    'You cannot undo this action',
          okText:     'Yes',
          okType:     'danger',
          cancelText: 'No',
          async onOk() {
            try {
              await handleRestoreFromBackupTemplate();
            } catch (err) {
              console.log(err)
              message.error('Error: ' + err)
            }
          },
          onCancel() {
          },
        })
      }
      const handleShowModalTutorial = () => {
        setShowModalTutorial(true);
      }
      const handleCancelShowModalTutorial = () => {
        setShowModalTutorial(false);
    }
     return (
        <div>
            <Button type='default' onClick={() => getBackupTemplateList(domain.name)}>Refresh</Button>
            <Spin tip="Loading..." size="large"  spinning={loadingBackupTemplateList}>
                 <Table
                    rowKey="id"
                    rowSelection={{
                      type: 'radio',
                      ...rowSelectionBackupTemplate,
                    }}
                    columns={columnsBackupTemplate}
                    dataSource={restoreTemplateList}
                    />
                <div style={{'color': 'red','fontStyle':'italic'}}>
                  Note: You need to setup Firewall, configure Secure FTP and enable SSH access in Plesk server before backup and restore templates. 
                  <a style={{'color':'blue'}} onClick={handleShowModalTutorial}> Read tutorial!</a>
                </div>
            </Spin>
            <Button danger onClick={() => confirmRestoreFromBackupTemplate()}> Restore Website From Selected Backup Template</Button>
            <EnableSshTutorialModal 
              showModalTutorial = {showModalTutorial}
              handleCancelShowModalTutorial = {handleCancelShowModalTutorial}
            />
        </div>
    );
}

export default RestoreTemplateDetails;