import { Button, message, Modal, Table, Tabs } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import SettingGoDaddyCreateModal from './components/SettingGoDaddyCreateModal';
import SettingsCloudflareCreateModal from './components/SettingsCloudflareCreateModal';
import { deleteCloudflare, getList as getCFList, postCloudflare, updateCloudflare } from './services/cloudflare-services';
import {
  deleteSettings, getGodadyList, getList, postSetting,
  updateSetting
} from './services/setting-service';
import { Cloudflare, Godaddy } from './types';

type Prop = {};

const Settings = (props: Prop) => {
  const { TabPane } = Tabs;
  const { confirm } = Modal;

  // Go daddy handle
  const [pageGoDaddy, setPageGoDaddy] = useState<number>(1);
  const [pageSizeGoDaddy, setPageSizeGoDaddy] = useState<number>(10);
  const [godaddyInfo, setGodaddyInfo] = useState<Godaddy & { id?: number } | Record<string, any>>();
  const [showGoDaddyModal, setShowGoDaddyModal] = useState<boolean>(false);

  const { data: totalGoDaddy } = useQuery('goddayCount', () => getList({
    where: {
      type: 'go_daddy',
      field: 'info',
    },
  }));

  const { data: goddayList, refetch: refetchGodaddy } = useQuery(
    ['godaddyList', { skip: (pageGoDaddy - 1) * pageSizeGoDaddy, limit: pageSizeGoDaddy }],
    () => getGodadyList({
      where: {
        type: 'go_daddy',
        field: 'info',
      },
    }));

  const createNewGodaddy = () => {
    setGodaddyInfo({});
    setShowGoDaddyModal(true);
  }

  const setPaginationGoDaddy = ({ page, pageSize }: { page: number, pageSize: number }) => {
    setPageGoDaddy(page);
    setPageSizeGoDaddy(pageSize);
  }

  const deleteGodaddy = (record: Godaddy) => {
    confirm({
      title: 'Are you sure to delete this record?',
      content: 'You cannot undo this action',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {
          const id = record.id;
          if (id) {
            await deleteSettings([id]);
            await refetchGodaddy();
          }
        } catch (err) {
          message.error('Error: ' + err);
        }
      },
      onCancel() {
      },
    });
  };

  const updateGoDadday = (record: Godaddy) => {
    setGodaddyInfo(record);
    setShowGoDaddyModal(true);
  }

  const columnsGodday: ColumnsType<Godaddy> = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Endpoint',
      dataIndex: 'endpoint',
      key: 'endpoint',
    },
    {
      title: 'key',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <div>
          <Button danger onClick={() => deleteGodaddy(record)}>Delete</Button>
          <Button onClick={() => updateGoDadday(record)}>Update</Button>
        </div>
      ),
    },
  ]

  const showGoDaddyModalEvent = async (state: boolean, data: { action: string, data: Godaddy }) => {
    setShowGoDaddyModal(state);
    if (data.action === 'CANCEL') {
      return;
    }
    if (data.action === 'OK') {
      if (!data.data.id) {
        const newAcc =await postSetting({
          type: 'go_daddy',
          field: 'info',
          value: JSON.stringify(data.data),
        });
        console.log('newAcc', newAcc)
        await updateSetting({
          id: newAcc.id,
          type: 'go_daddy',
          field: 'info',
          value: JSON.stringify({...JSON.parse(newAcc.value), id: newAcc.id}),
        })
      } else {
        await updateSetting({
          id: data.data.id,
          type: 'go_daddy',
          field: 'info',
          value: JSON.stringify(data.data),
        })
      }
      await refetchGodaddy();
    }
  };
  // END: Go daddy handle

  // handle Setting Cloudflare

  // state
  const [showModal, setShowModal] = useState<boolean>(false);
  const [cloudflareInfo, setCloudflareInfo] = useState<Cloudflare>();
  const [cloudflareList, setCloudflareList] = useState<Cloudflare[]>([]);

  // methods
  const showModalEvent = async (state: boolean, event?: { action: string, data?: Cloudflare }) => {
    setShowModal(state);
    if (event?.action === 'OK') {
      if (!event?.data) {
        return;
      }
      if (!event?.data?.id) {
        const result = await postCloudflare(event.data)
        const temp = cloudflareList.slice();
        temp.push(result)
        setCloudflareList(temp);
      } {
        updateCloudflare(event.data);
        const temp = cloudflareList.slice();
        const indexRecord = temp.findIndex(item => item.id === event.data?.id);
        temp[indexRecord] = event.data;
        setCloudflareList(temp);
      }
    }
  }

  const deleteCF = async (record: Cloudflare) => {
    if (record.id) {
      await deleteCloudflare([record.id]);
      const temp = cloudflareList.filter((item, index) => item.id !== record.id);
      setCloudflareList(temp);
    }

  }

  const updateCF = (record: Cloudflare) => {
    setCloudflareInfo(record);
    setShowModal(true);
  }

  const columnsCloudflare: ColumnsType<Cloudflare> = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Password',
      dataIndex: 'password',
      key: 'password',
      render: () => <p>********</p>
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <div>
          <Button danger onClick={() => deleteCF(record)}>Delete</Button>
          <Button onClick={() => updateCF(record)}>Update</Button>
        </div>
      ),
    },
  ]

  // END: handle Setting Cloudflare

  // hooks
  useEffect(() => {
    getCFList({
      skip: 0,
      limit: 100,
      where: {}
    }).then(result => {
      setCloudflareList(result);
    })
  }, []);

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Cloudflare" key="1">
          <div>
            <Button type="primary" onClick={() => setShowModal(true)} >
              Create New
            </Button>
            <div style={{height: 8}}/>
            <Table
              rowKey="id"
              columns={columnsCloudflare}
              dataSource={cloudflareList}
              pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
              rowClassName="editable-row"
            />
          </div>
        </TabPane>
        <TabPane tab="GoDaddy" key="3">
          <Button type="primary" onClick={() => createNewGodaddy()}>
            Create New
          </Button>
          <div style={{height: 8}}/>
          <Table
            rowKey="id"
            columns={columnsGodday}
            dataSource={goddayList}
            rowClassName="editable-row"
            pagination={{
              current: pageGoDaddy,
              pageSize: pageSizeGoDaddy,
              total: totalGoDaddy,
              onChange: (page, pageSize) => {
                setPaginationGoDaddy({ page, pageSize });
              },
            }}
          />
        </TabPane>
      </Tabs>

      <SettingsCloudflareCreateModal
        showModal={showModal}
        cloudflareInfo={cloudflareInfo}
        showModalEvent={showModalEvent}
      />

      <SettingGoDaddyCreateModal
        showModal={showGoDaddyModal}
        godaddyInfo={godaddyInfo}
        showModalEvent={showGoDaddyModalEvent}
      />
    </div>
  );
};

export default Settings;
