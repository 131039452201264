import axios from "axios";
import JwtService from "./jwt.service";
import config from "../../config";

const ApiService = {
    endPoint: config.BASE_URL,
    init() {
        axios.defaults.baseURL = config.BASE_URL;
    },

    setHeader() {
        axios.defaults.headers.common[
            "Authorization"
        ] = `${JwtService.getToken()}`;
    },

    setAxiosGlobal(token: string) {
        // axios.defaults.headers = { 'Authorization': token };
        axios.defaults.headers.common[
            "Authorization"
        ] = token;
    },


    query(resource, filter) {
        return axios.get(resource, {params: {filter : filter}}).catch(error => {
            throw new Error(`ApiService ${error}`);
        });
    },

    count(resource, filter) {
        return axios.get(resource, {params: {where : filter.where}}).catch(error => {
            throw new Error(`ApiService ${error}`);
        });
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @param slug
     * @returns {*}
     */
    get(resource, slug = "") {
        return axios.get(`${resource}/${slug}`).catch(error => {
            throw new Error(`ApiService ${error}`);
        });
    },

    getWithParams(resource, params = {}) {
        return axios.get(resource, { params }).catch(error => {
            throw new Error(`ApiService ${error}`);
        });
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params) {
        return axios.post(`${resource}`, params);
    },

    /**
     * Send the PATCH HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    patch(resource, slug, params) {
        return axios.patch(`${resource}/${slug}`, params);
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        return axios.put(`${resource}`, params);
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    delete(resource) {
        return axios.delete(resource).catch(error => {
            throw new Error(`ApiService ${error}`);
        });
    }
}

export default ApiService;