import React from 'react';
import {  Modal } from 'antd';

const EnableSshTutorialModal = (
    {
        showModalTutorial,
        handleCancelShowModalTutorial,
    } :
    {
        showModalTutorial : boolean,
        handleCancelShowModalTutorial : () => void,
    }
)  => {
 

    return (
        <Modal
        destroyOnClose
        open={showModalTutorial}
        title="How to setup Firewall, configure Secure FTP and enable SSH access in Plesk?"
        onOk={handleCancelShowModalTutorial}
        onCancel={handleCancelShowModalTutorial}
        width={800}
        style={{ top: 20 }}
        >
    <p>
    <b>Step 1: Setting Firewall</b><br/>
    1. Login in to Plesk.<br/>
    2. Go to <b>Tools & Settings</b>, select <b>Firewall</b><br/>
    3. Select <b>Modify Plesk Firewall Rules</b><br/>
    4. Change the values of the options: <b>FTP server passive ports</b>, <b>FTP server</b>, <b>SSH (secure shell) server</b> to <b>Allow</b><br/>
    5. Finally select <b>Apply Changes</b><br/><br/>
    <b>Step 2: Configure secure FTP in Plesk</b><br/>
    1. Go to <b>Tools & Settings</b>, select <b>Security Policy</b> and select the following option: <br/>
    – <b>Allow both secure FTPS and non-secure FTP connections</b> <br/><br/>
    <b>Step 3: Enable SSH connection</b><br/>
    1. Go to <b>Subscriptions</b>, choose Your Site<br/>
    2. Select <b>FTP Access</b> and choose ftp account <br/>
    3. Change <b>Username</b> to <b>[domain name]_ftp</b> <i>(e.g. world2arcade.com_ftp)</i> <br/>
    4. Set <b>Password</b> and <b>Confirm password </b> to <b>changeme1Q@123XYZ</b><br/>
    5. Change <b>Access to the server over SSH</b> to the option: <b>/bin/bash</b>
    </p>
  </Modal>
    )
}


export default EnableSshTutorialModal;