import React, { useEffect, useState } from 'react';
import { Button, message, Modal, Space, Spin, Table } from 'antd';
import ApiService from '@/services/api.service';
import { useLocation } from 'react-router-dom';
import { DomainPlesk, EmailAccount } from '@/types';
import { ColumnsType } from 'antd/lib/table';
import CreateEmailAddressModal from './create-email-address-modal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import UpdateEmailAddressModal from './update-email-address-modal';
const { confirm } = Modal;

const MailDetails = (
    {domain}: {domain : DomainPlesk}
) => {
    const [emailAccountList, setEmailAccountList] = useState<any[]>([]);
    const [showModalCreateEmailAddress, setShowModalCreateEmailAddress] = useState<boolean>(false);
    const [showModalUpdateEmailAddress, setShowModalUpdateEmailAddress] = useState<boolean>(false);
    const [loadingEmailAccountList, setLoadingEmailAccountList] = useState<boolean>(false);
    const [emailEditedInfo, setEmailEditedInfo] = useState<EmailAccount>();
    const { state } = useLocation();
    const getEmailAccountList = async () => {
        try {
            setLoadingEmailAccountList(true);
           const res = await ApiService.post('/Domains/getEmailAccountList', {infoEncoded : state});
           if(res?.data?.result?.data?.code===1){
            alert(res.data.result.data.stderr);
            console.error(res.data.result.data.stderr);
           }else{
            let list = res?.data?.result.filter(mail =>  mail.name.includes(domain.name) &&  mail.type === 'mailname');
            setEmailAccountList(list);
           }
           setLoadingEmailAccountList(false);
        } catch (err) {
            setLoadingEmailAccountList(false);
            message.error(err, 10);
            console.error(err);
        }
    }
    const handleDeleteMailAccount = async (mail : EmailAccount) => {
        confirm({
            title: `Are you sure to delete email ${mail.name}?`,
            icon: <ExclamationCircleOutlined />,
            content: 'You cannot undo this action!',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const res = await ApiService.post('/Domains/deleteMail', {
                        mail: mail.name,
                        infoEncoded : state});
                    await getEmailAccountList();
                    setShowModalCreateEmailAddress(false);
                    message.success('Email address has been deleted!');
                } catch (err) {
                     message.error(err, 10);
                     console.error(err);
                    setShowModalCreateEmailAddress(false);

                 }
            },
            onCancel() {
            },
          });

    }
    const handleEditMailAccount = (mail : EmailAccount) => {
        console.log(mail);
        let newMail = JSON.parse(JSON.stringify(mail));
        setEmailEditedInfo(newMail);
        setShowModalUpdateEmailAddress(true);

    }
    useEffect(() => {
        getEmailAccountList();
    },[]);

    const columnsEmailAccount: ColumnsType<EmailAccount> = [
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: 'name',
          dataIndex: 'name',
          key: 'name',
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
              <>
                <Space>
                  <Button onClick={() => handleEditMailAccount(record)}>Edit</Button>
                  <Button danger onClick={() => handleDeleteMailAccount(record)}>Delete</Button>
                </Space>
              </>
            ),
          }
        
      ];
    return (
        <div>
            <Button type="primary" onClick={() => setShowModalCreateEmailAddress(true)}>Create Email Address</Button>
            <Spin tip="Loading..." size="large"  spinning={loadingEmailAccountList}>
                <Table
                    rowKey="name"
                    columns={columnsEmailAccount}
                    dataSource={emailAccountList}
                />
            </Spin>
            <CreateEmailAddressModal 
                domain={domain}
                showModalCreateEmailAddress={showModalCreateEmailAddress}
                setShowModalCreateEmailAddress={setShowModalCreateEmailAddress}
                getEmailAccountList={getEmailAccountList}
            />
            <UpdateEmailAddressModal 
                editEmail={emailEditedInfo?emailEditedInfo:{name:'',type:''}}
                showModalUpdateEmailAddress={showModalUpdateEmailAddress}
                setShowModalUpdateEmailAddress={setShowModalUpdateEmailAddress}
                getEmailAccountList={getEmailAccountList}

            />
            
        </div>
    );
}

export default MailDetails;