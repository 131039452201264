import React from 'react';

const Logo = () => (
  <div
    style={{
      height: 64,
      padding: '12px 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRight: '1px solid rgb(240, 240, 240)',
      borderBottom: '1px solid rgb(240, 240, 240)',
      backgroundColor: '#96D2F9',
    }}
  >
    <img
      alt="logo"
      src="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
      style={{
        height: 32,
        marginRight: 12,
        verticalAlign: 'middle',
        borderStyle: 'none',
      }}
    />
    <span style={{ fontSize: 20, fontWeight: 500 }}>DOMAINLABS</span>
  </div>
);

export default Logo;
