import React, { useEffect } from 'react';
import { Form, Input, Modal } from 'antd';
import { DigitalInfo } from '../types';
import type { FormInstance } from 'antd/es/form';

type Prop = {
    showModal: boolean;
    showModalEvent: Function;
    digitalInfo?: { id?: number, value: DigitalInfo };
    digitalInfoEvent: Function;
};

const CreateDOModal = (props: Prop) => {
    const formRef = React.useRef<FormInstance>(null);

    const handleOK = () => {
        const name = formRef.current?.getFieldValue('name');
        const token = formRef.current?.getFieldValue('token');
        if (props.digitalInfo) {
            const info = {...props.digitalInfo};
            info.value.name = name;
            info.value.token = token;
            props.digitalInfoEvent(info);
            return;
        }
        props.digitalInfoEvent({
            value: { name, token }
        });
    }

    const handleCancel = () => {
        props.showModalEvent(false)
    }

    useEffect(() => {
        setTimeout(() => {
            if (props.digitalInfo) {
                formRef.current?.setFieldsValue({ 
                    name: props.digitalInfo?.value?.name,
                    token: props.digitalInfo?.value?.token, 
                }); 
            }
        }, 100);
    }, [props.digitalInfo]);

    return (
        <Modal
            destroyOnClose
            title={props.digitalInfo?.id ? "Edit Digital Ocean Account": "Create New Digital Ocean Account"}
            getContainer={false}
            open={props.showModal}
            onOk={handleOK}
            onCancel={handleCancel}
        >
            <Form
                ref={formRef}
            >
                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="token" label="Token" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CreateDOModal;
