import ApiService from '@/services/api.service';
import { DomainPlesk } from '@/types';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, message, Modal, Row, Tabs } from 'antd';
import React , { useState } from 'react';
import { useLocation } from 'react-router-dom';
const { TextArea } = Input;
const CreateEmailAddressModal = (
    {
        domain,
        showModalCreateEmailAddress,
        setShowModalCreateEmailAddress,
        getEmailAccountList
    } : {
        domain : DomainPlesk,
        showModalCreateEmailAddress : boolean,
        setShowModalCreateEmailAddress : Function,
        getEmailAccountList : Function
    }
) => {
    const { state } = useLocation();
    const checkPasswordStrength = (password: string ='') => {
        let strength: number = 0;
        if (password.match(/[a-z]+/)) {
          strength += 1;
        }
        if (password.match(/[A-Z]+/)) {
          strength += 1;
        }
        if (password.match(/[0-9]+/)) {
          strength += 1;
        }
        if (password.match(/[$@#&!]+/)) {
          strength += 1;
      
        }
      
        if(strength<4 || password.length<9){
            return false;
        } 
        return true;
        
    }
    const initParams = () => {
        setEmailAddress('');
        setEmailPassword('');
        setConfirmEmailPassword('');
        setListAliases('');
        setListForwardingAddresses('');
        setStatusEmailAddress('');
        setStatusPassword('');
        setEnableLogInToPlesk(false);
        setEnableMailbox(true);
    }
    const handleCreateEmailAddress = async () => {
        if(!emailAddress){
            setStatusEmailAddress('Please input Email address.')
            return;
        }
        setStatusEmailAddress('');
        if(!checkPasswordStrength(emailPassword)){
            const status = `Minimum length of password is 9. 
            At least 1 letter between lowercase [ a - z ].
            At least 1 letter between uppercase [ A - Z ].
            At least 1 number between [ 0 - 9 ].
            At least 1 special character from [ $@#&! ].
            `;
            setStatusPassword(status);
            return;
        }
        if(emailPassword!==confirmEmailPassword){
            setStatusPassword('Password and confirm password does not match.');
            return;
        }
        setStatusPassword('');
        confirm({
            title: 'Are you sure to create email address?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const res = await ApiService.post('/Domains/createMail', {
                        newMail: emailAddress + '@' + domain.name, 
                        newPassword: emailPassword,
                        enableMailbox: enableMailbox,
                        mailboxSize: '',
                        enableForwarding: enableForwarding,
                        listForwardingAddresses: listForwardingAddresses,
                        enableLogInToPlesk: enableLogInToPlesk,
                        listAliases: listAliases,
                        infoEncoded : state});
                    if(res?.data?.result?.data?.code===1){
                        alert(res.data.result.data.stderr);
                        console.error(res.data.result.data.stderr);
                    }
                    await getEmailAccountList();
                    setShowModalCreateEmailAddress(false);
                    initParams();
                    message.success('Email address has been created!');
                } catch (err) {
                     message.error(err, 10);
                     console.error(err);
                    setShowModalCreateEmailAddress(false);
                    initParams();
                 }
            },
            onCancel() {
            },
          });

       
    }
    const handleCancelCreateEmailAddress = () => {
        setShowModalCreateEmailAddress(false);
        initParams();
    }
    const { confirm } = Modal;
    const { TabPane } = Tabs;
    const [statusPassword, setStatusPassword] = useState<string>();
    const [statusEmailAddress, setStatusEmailAddress] = useState<string>();
    const [emailAddress, setEmailAddress] = useState<string>();
    const [emailPassword, setEmailPassword] = useState<string>();
    const [confirmEmailPassword, setConfirmEmailPassword] = useState<string>();
    const [enableMailbox, setEnableMailbox] = useState<boolean>(true);
    const [enableLogInToPlesk, setEnableLogInToPlesk] = useState<boolean>(false);
    const [enableForwarding, setEnableForwarding] = useState<boolean>(false);
    const [listForwardingAddresses, setListForwardingAddresses] = useState<string>();
    const [listAliases, setListAliases] = useState<string>();

    return (
        <Modal
        destroyOnClose
        open={showModalCreateEmailAddress}
        title="Create Email Address"
        onOk={handleCreateEmailAddress}
        onCancel={handleCancelCreateEmailAddress}
        width={800}
        style={{ top: 20 }}
        >
            <Tabs defaultActiveKey="1">
                <TabPane tab="General" key="1">
                    <Form>
                        
                            <Row>
                                <Col span={8}><Form.Item label="Email address" rules={[{ required: true }]} /></Col>
                                <Col span={8}>
                                    <Input status={emailAddress?'':"error"} value={emailAddress} onChange={e => setEmailAddress(e.target.value)} />
                                    <span style={{color: 'red',padding:'5px'}}> {statusEmailAddress}</span>
                                    </Col>
                                <Col span={8}> &nbsp;{'@'+domain.name}</Col>
                            </Row>
                            
                         
                            
                            <Row>
                                <Col span={8}><Form.Item label="Password" rules={[{ required: true }]} /></Col>
                                <Col span={8}>
                                    <Input.Password status={statusPassword?'error':''} value={emailPassword} onChange={e => setEmailPassword(e.target.value)}/>
                                    <span style={{color: 'red',padding:'5px'}}> {statusPassword}</span>
                                </Col>
                                <Col span={8}></Col>
                            </Row>
                            <Row>
                                <Col span={8}><Form.Item label="Confirm password" rules={[{ required: true }]} /></Col>
                                <Col span={8}><Input.Password status={statusPassword?'error':''} value={confirmEmailPassword} onChange={e => setConfirmEmailPassword(e.target.value)} /></Col>
                                <Col span={8}></Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <Checkbox checked={enableLogInToPlesk} onChange={(e) => setEnableLogInToPlesk(e.target.checked)}>Can be used to log in to Plesk</Checkbox>
                                </Col>
                                <Col span={8}></Col>
                                <Col span={8}></Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                <Checkbox checked={enableMailbox} onChange={(e) => setEnableMailbox(e.target.checked)}>Mail Box (Default size)</Checkbox>
                                </Col>
                                <Col span={8}></Col>
                                <Col span={8}></Col>
                            </Row>
                           
                    </Form>
                </TabPane>
                <TabPane tab="Forwarding" key="2">
                    <p>Set up forwarding of email messages to one or more email addresses.</p>
                    <Form>
                        <Form.Item
                            name="forwarding"
                        >
                            <Checkbox checked={enableForwarding} onChange={(e) => setEnableForwarding(e.target.checked)}>Switch on mail forwarding</Checkbox>
                        </Form.Item>
                        <Form.Item name="listForwardEmail" label="Forward incoming messages to the following email address ">
                            <TextArea 
                                disabled={!enableForwarding}
                                rows={5} 
                                value={listForwardingAddresses} 
                                onChange={e => setListForwardingAddresses(e.target.value)}></TextArea>
                        </Form.Item>
                    </Form>
                    <Row>
                                <i>Note: Specify email addresses. Separate addresses with commas.</i>
                        </Row>
                </TabPane>
                <TabPane tab="Email Aliases" key="3">
                    <p>Email aliases are alternative email addresses associated with a primary email address. All mail sent to email aliases will be received by the primary email address.</p>
                    <Form>
                        <Row>
                            <Col span={6}><Form.Item name="listAliases" label="Email aliases" rules={[{ required: false }]} /></Col>
                            <Col span={12}><TextArea rows={5} value={listAliases} onChange={e => setListAliases(e.target.value)} ></TextArea></Col>
                            
                         
                        </Row>
                        <Row>
                                <i>Note: Specify email aliases. Separate aliases with commas. Don't need to type <strong>@{domain.name}</strong>. E.g: Alias1, Alias2, Alias3</i>
                        </Row>
                       
                    </Form>
                </TabPane>
            </Tabs>
        </Modal>
    );
}
export default CreateEmailAddressModal;
