import React, { useState, useEffect } from 'react';
import { Button, Input, Modal } from 'antd';
import { Cloudflare } from '../types';

type Prop = {
    showModal: boolean;
    showModalEvent: (state: boolean, data?: { action: string, data?: Cloudflare }) => void;
    cloudflareInfo: Cloudflare | undefined;
};

const SettingsCloudflareCreateModal = (props: Prop) => {
    // state
    const [cloudflareInfo, setCloudflareInfo] = useState<Cloudflare>({ name: '', username: '', password: '' });

    // methods
    const handleOK = () => {
        props.showModalEvent(false, { action: 'OK', data: cloudflareInfo });
    }

    const handleCancel = () => {
        props.showModalEvent(false, { action: 'CANCEL' })
    }

    useEffect(() => {
        if (props.cloudflareInfo) {
            setCloudflareInfo(props.cloudflareInfo);
        }
    }, [props.cloudflareInfo])

    return (
        <Modal
            title={cloudflareInfo?.id ? "Edit Cloudlare" : "Create New Cloudlare"}
            getContainer={false}
            open={props.showModal}
            onOk={handleOK}
            onCancel={handleCancel}
        >
            <p>NAME: (*)</p>
            <Input
                value={cloudflareInfo?.name}
                onChange={e => cloudflareInfo && setCloudflareInfo({ ...cloudflareInfo, name: e.target.value })}
            />

            <p>USERNAME: </p>
            <Input
                value={cloudflareInfo?.username}
                onChange={e => cloudflareInfo && setCloudflareInfo({ ...cloudflareInfo, username: e.target.value })}
            />

            <p>PASSWORD: (*)</p>
            <Input
                value={cloudflareInfo?.password}
                onChange={e => cloudflareInfo && setCloudflareInfo({ ...cloudflareInfo, password: e.target.value })}
            />
        </Modal>
    )
}

export default SettingsCloudflareCreateModal;
