import { WpInstanceDetail } from '@/pages/Settings/types';
import { BackupTemplate, DomainPlesk, WordpressSite } from '@/types';
import { Button, Col, message, Modal, Row, Space, Switch, Tabs, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import MailDetails from './Mail/mails-details';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ApiService from '@/services/api.service';
import BackupTemplateDetails from './BackupTemplate/backuptemplates-details';
import RestoreTemplateDetails from './BackupTemplate/restoretemplates-details';
import type { TabsProps } from 'antd';
const PleskDetails = ({
  domain,
  listThemes,
  listPlugins,
  listWordpress,
  listSubDomains,
  loadingState,
  allowCommentStatus,
  // getAllowCommentStatusWordpressInstance,
  backupTemplateList,
  restoreTemplateList,
  loadingBackupTemplateList,
  getBackupTemplateList,
  handleAllowComment,
  handleDeleteWordpress,
  handleDeletePlugin,
  handleDeleteThemeWP,
  handleDeleteSubDomain,
  handleAddSubDomain,
  handleCreateBackupFile,
  activePlugin,
  deactivePlugin,
  activeTheme,
}: {
  domain: DomainPlesk,
  listThemes: WpInstanceDetail[],
  listPlugins: WpInstanceDetail[],
  listWordpress: WordpressSite[],
  listSubDomains: DomainPlesk[],
  loadingState: boolean,
  allowCommentStatus: (instanceId:string) => Promise<boolean>,
  // getAllowCommentStatusWordpressInstance: (instanceId:string) => Promise<boolean>,
  backupTemplateList : BackupTemplate[],
  restoreTemplateList : BackupTemplate[],
  loadingBackupTemplateList : boolean,
  getBackupTemplateList : (domainName: string) => Promise<void>,
  
  handleAllowComment: (checked: boolean,instanceId: number) => void,
  handleDeleteWordpress: (record: any) => void,
  handleDeletePlugin: (name: string) => void,
  handleDeleteThemeWP: (name: string) => void,
  handleDeleteSubDomain: (domain: DomainPlesk) => void,
  handleAddSubDomain: (domain: DomainPlesk) => void,
  handleCreateBackupFile: (instanceId: number, domainName: string) => void,
  activePlugin: (name: string, index: number) => void,
  deactivePlugin: (name: string, index: number) => void,
  activeTheme: (name: string, index: number) => void,
}) => {

  const wpInstances = listWordpress.filter(
    (instance: Record<string, any>) => instance.mainDomainId === domain.id
  );
  const { confirm } = Modal;
  const [isAllow, setIsAllow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const getAllowCommentStatus = async () => {
    setLoading(true);
    const temp = await allowCommentStatus(wpInstances[0].id.toString());
    setIsAllow(temp);
    setLoading(false);
  }
  const callHandleAllowComment = async (checked: boolean, instanceId : number) => {
    setIsAllow(checked);
     handleAllowComment(checked,instanceId);
  }
  
  useEffect(()=>{
    getAllowCommentStatus();
  },[])
  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'Info',
      children: (
        <div>
          {wpInstances.map((wp, index) => (
            <div key={index}>
              {index + 1}. {wp.name} - {wp.siteUrl} - {wp.stateText} -{' '}
              {wp.version}
              <Button type="link" danger onClick={() => handleDeleteWordpress(wp)}>Delete</Button>
            </div>
          ))}
        </div>
      )
    },
    {
      key: '2',
      label: 'Plugins',
      children: (
        <div>
          <Spin tip="Loading..." size="large" spinning={listPlugins?.length === 0 ? true : false}>
            {listPlugins && listPlugins.map((plugin, index) => (
              <div key={index}>
                <Row>
                  <Col span={6}>
                    {index + 1}. {plugin.name} - {plugin.version} - <Tag color={plugin.status === 'active' ? "success" : "warning"}>{plugin.status}</Tag>
                  </Col>
                  <Col span={6}>
                    <Button type="link" danger onClick={() => handleDeletePlugin(plugin.name)}>Delete</Button>
                    {plugin.status === 'active' && <Button type="link" onClick={() => deactivePlugin(plugin.name, index)}>Deactivate</Button>}
                    {plugin.status !== 'active' && <Button type="link" onClick={() => activePlugin(plugin.name, index)}>Activate</Button>}
                    {
                      loadingState && <Spin />
                    }
                  </Col>
                </Row>
              </div>
            ))}
          </Spin>
        </div>
      )
    },
    {
      key: '3',
      label: 'Themes',
      children: (
        <div>
          {listThemes && listThemes.map((theme, index) => (
            <div key={index}>
              <Row>
                <Col span={6}>
                {index + 1}. {theme.name} - {theme.version} - <Tag color={theme.status === 'active' ? "success" : "warning"}>{theme.status}</Tag>
                </Col>
                <Col span={6}>
                  {theme.status !== 'active' && <Button type="link" danger onClick={() => handleDeleteThemeWP(theme.name)}>Delete</Button>}
                  {theme.status !== 'active' && <Button type="link" onClick={() => activeTheme(theme.name, index)}>Activate</Button>}
                  {
                    loadingState && <Spin />
                  }
                </Col>
              </Row>
            </div>
          ))}
        </div>
      )
    },
    {
      key: '4',
      label: 'Subdomains',
      children: (
        <div>
            {listSubDomains && listSubDomains.map((subDomain, index) => (
              <div key={index}>
                  {index + 1}. {subDomain.name}
                  <Button type="link" danger onClick={() => handleDeleteSubDomain(subDomain)}>Delete</Button>
              </div>
            ))}
            <Button type="primary" onClick={() => handleAddSubDomain(domain)}>Add Subdomain</Button>
        </div>
      )
    },
    {
      key: '5',
      label: 'Settings',
      children: (
        <div>
          <Spin tip="Loading..." spinning={loading}>
          {wpInstances.map((wp,index) =>(
            <div key={index}>
              <Space>              
                  <Switch checked={isAllow} onChange={checked => callHandleAllowComment(checked,wp.id)}/> Allow people to submit comments on new post. 
                  <i>(Changes here will only be applied to new posts.)</i>
              </Space>
          </div>
            ))}
            </Spin>
        </div>
      )
    },
    {
      key: '6',
      label: 'Mail',
      children: (
        <div>
          <MailDetails
              domain={domain}
            />
        </div>
      )
    },
    {
      key: '7',
      label: 'Create Backup Template',
      children: (
        <div>
           <BackupTemplateDetails
              getBackupTemplateList={getBackupTemplateList}
              backupTemplateList={backupTemplateList}
              loadingBackupTemplateList={loadingBackupTemplateList}
              domain={domain}
            />
            <Button type='primary' 
              onClick={() => handleCreateBackupFile(wpInstances[0].id, domain.name)}
            >Create Backup Template</Button>
        </div>
      )
    },
    {
      key: '8',
      label: 'Restore From Backup Template',
      children: (
        <div>
          <RestoreTemplateDetails
             getBackupTemplateList={getBackupTemplateList}
              restoreTemplateList={restoreTemplateList}
              loadingBackupTemplateList={loadingBackupTemplateList}
              domain={domain}
            />
        </div>
      )
    },
  ];
  return (
      <Tabs defaultActiveKey="1" items={tabItems}/>
       
  );
};

export default PleskDetails;
