import { Button, Skeleton } from 'antd';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import AppTable, { Column } from '../components/AppTable';
import PleskModal from './components/PleskModal';
import { dropletsCount, getDroplets } from './services/droplets-services';

const Droplets = () => {
  const { data: total } = useQuery('count', dropletsCount);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const {
    isLoading,
    isError,
    error,
    refetch,
    data: dataList,
  } = useQuery(
    ['portal-list', { skip: (page - 1) * pageSize, limit: pageSize }],
    () => getDroplets()
  );
  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const isFleskVPS = (vpsInfo: Record<string, any>) =>
    vpsInfo?.image?.name?.includes('Plesk');

  const connectPlesk = () => {
    setModalVisible(true);
  };

  const visiblePleskEvent = (visible: boolean) => {
    setModalVisible(visible);
  };

  const columns: (Column | any)[] = [
    { title: 'ID', key: 'id', dataIndex: 'id' },
    {
      title: 'NAME',
      key: 'name',
      dataIndex: 'name',
      editable: true,
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (_, record) =>
        isFleskVPS(record) && (
          <Button type="primary" onClick={connectPlesk}>
            Connect
          </Button>
        ),
    },
  ];

  if (isLoading) {
    return (
      <div>
        <Skeleton />
      </div>
    );
  }
  if (isError) {
    return <div>Error {error as any}</div>;
  }

  // const setSelectedRowKeys = (keys: number[]) => {};

  return (
    <div>
      <div>
        <AppTable
          columns={columns}
          data={dataList?.droplets}
          page={page}
          total={dataList?.meta?.total}
          pageSize={pageSize}
          // selectedRowKeys={selectedRowKeys}
          // setPagination={setPagination}
          // setSelectedRowKeys={setSelectedRowKeys}
        />
      </div>

      <PleskModal open={isModalVisible} visibleEvent={visiblePleskEvent} />
    </div>
  );
};

export default Droplets;
