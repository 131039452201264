import ApiService from '@/services/api.service'
import {DropletImage, DropletSize} from '@/types'
import {base64Encode} from '@/util/handle-string'
import {ConsoleSqlOutlined, ExclamationCircleOutlined} from '@ant-design/icons'
import {Button, Collapse, Table, Modal, Tag, Form, Input, message, Spin, Space, Typography, Tabs} from 'antd'
import type {TabsProps} from 'antd'
import {ColumnsType, TablePaginationConfig} from 'antd/lib/table'
import React, {useEffect, useState, useRef} from 'react'
import {useQuery} from 'react-query'
import {useNavigate} from 'react-router-dom'
import {Column} from '../components/AppTable'
import {dropletsCount, getDroplets} from '../Droplets/services/droplets-services'
import {deleteSettings, findOne, getList, postSetting, updateSetting} from '../Settings/services/setting-service'
import {PleskAccount} from '../Settings/types'
import CreateDOModal from './components/CreateDOModal'
import PleskConnectInfo from './components/PleskConnectInfo'
import {DigitalInfo} from './types'
import type {FilterValue, SorterResult} from 'antd/es/table/interface'
import {TableCurrentDataSource} from 'antd/lib/table/interface'
import { SearchOutlined } from '@ant-design/icons';
import type { FilterConfirmProps } from 'antd/es/table/interface';

const {confirm} = Modal
const {Panel} = Collapse

type Props = {
	runUploadPlesk: (state: any) => void,
	isUploadPlesk: boolean,
	readOnly: boolean,
	setOpenListWordpressModal: any,
	fetchListWordpressSites: any
};

export const DigitalOceans = ({
	                              runUploadPlesk,
	                              isUploadPlesk,
	                              readOnly,
	                              setOpenListWordpressModal,
	                              fetchListWordpressSites
                              }: Props) => {

	const [pleskAccounts, setPleskAccounts] = useState<{
		id?: number;
		value: PleskAccount[];
	}>()
	const [showCreateNewDialog, setShowCreateNewDialog] = useState<boolean>(false)

	const navigate = useNavigate()
	const [digitalAccounts, setDigitalAccounts] = useState<{ id?: number, value: DigitalInfo, droplets: any[] }[]>([])
	const [digitalAccount, setDigitalAccount] = useState<{ id?: number, value: DigitalInfo, droplets: any[] }>()
	const [loadingDOAccount, setLoadingDOAccount] = useState<boolean>(false)
	const onChange = (key: string | string[]) => {
		if (Array.isArray(key)) {
			if (key.length > 1) {

			}
		}
	}

	const {data: total} = useQuery('count', dropletsCount)
	const [page, setPage] = useState<number>(1)
	const [pageSize, setPageSize] = useState<number>(10)
	// const {
	//     isLoading,
	//     isError,
	//     error,
	//     refetch,
	//     data: dataList,
	// } = useQuery(
	//     ['portal-list', { skip: (page - 1) * pageSize, limit: pageSize }],
	//     () => getDroplets('dop_v1_61b9e2dfcad928d1f3089e52b7f7b15c3c72441ea4f68ec06a8b79422a641eba')
	// );

	const isFleskVPS = (vpsInfo: Record<string, any>) => {
		// vpsInfo?.image?.name?.includes('Plesk');
		return true
	}

	const [openConnectPleskModal, setOpenConnectPleskModal] = useState<boolean>(false)
	const [openCreateDropletModal, setOpenCreateDropletModal] = useState<boolean>(false)
	const [newDropletName, setNewDropletName] = useState<string>()
	const [listDropletSizes, setListDropletSizes] = useState<DropletSize[]>([])
	const [listDropletImages, setListDropletImages] = useState<DropletImage[]>([])
	const [currentVPS, setCurrentVPS] = useState<Record<string, any>>({})
	const connectPlesk = (vpsInfo: Record<string, any>): void => {
		setOpenConnectPleskModal(true)
		setCurrentVPS(vpsInfo)

	}
	const runConnectPlesk = (): void => {
		setOpenConnectPleskModal(false)
		const networks = currentVPS.networks.v4
		const temp = networks.filter(network => network.type === 'public')
		const apiEndpoint = temp.length ? 'https://' + temp[0].ip_address + ':443/api/v2' : ''
		const vpsIP = temp[0].ip_address
		const values = {
			username: pleskUsername,
			password: pleskPassword
		}
		const authenInfo: string = `Basic ${base64Encode(`${values.username}:${values.password}`)}`
		if (isUploadPlesk) {
			runUploadPlesk({authenInfo, apiEndpoint})
		} else if (readOnly) {
			setOpenConnectPleskModal(false)
			setOpenListWordpressModal(true)
			fetchListWordpressSites({authenInfo, apiEndpoint})
		} else {
			navigate('/admin/plesks', {state: {authenInfo, apiEndpoint, vpsIP}})
		}
	}

	const columns: (Column | any)[] = [
		{title: 'ID', key: 'id', dataIndex: 'id'},
		{
			title:     'NAME',
			key:       'name',
			dataIndex: 'name',
			editable:  true,
		},
		{
			title:     'Disk',
			key:       'disk',
			dataIndex: 'disk',
			editable:  true,
			render:    text => text + ' GB',
			sorter:    (a, b) => a.disk - b.disk,
		},
		{
			title:     'Memory',
			key:       'memory',
			dataIndex: 'memory',
			editable:  true,
			render:    text => text / 1024 + ' GB',
			sorter:    (a, b) => a.memory - b.memory,

		},
		{
			title:     'Image disk',
			key:       'image',
			dataIndex: 'image',
			editable:  true,
			render:    (_, record) => (
				<>
					<Tag>{record.image['name']}</Tag>
					<Tag color={record.image['status'] === 'available' ? 'success' : 'error'}>
						{record.image['status']}
					</Tag>
				</>
			)
		},
		{
			title:     'IP Address',
			key:       'networks',
			dataIndex: 'networks',
			editable:  true,
			render:    (_, record) => {
				const networks = record.networks.v4
				const temp = networks.filter(network => network.type === 'public')
				const apiEndpoint = temp.length ? 'https://' + temp[0].ip_address : ''
				return (<a href={apiEndpoint} target="_blank">{apiEndpoint}</a>)
			}
		},

		{
			title:  'ACTION',
			key:    'action',
			render: (_, record) =>
				        isFleskVPS(record) && (
					        <Button type="primary" onClick={() => connectPlesk(record)}>
						        Connect
					        </Button>
				        ),
		},
	]


	const getPlesksAccount = async (): Promise<void> => {
		const pleskFilter = {
			where: {
				type:  'plesk',
				field: 'account',
			},
		}
		const result = await getList(pleskFilter)
		if (result[0]?.value) {
			const accounts = JSON.parse(result[0].value)
			setPleskAccounts({
				value: accounts,
			})
		}
	}

	const getPlesk = (id: number) => {
		if (!pleskAccounts) {
			return
		}
		const pleskInfo = pleskAccounts.value.find(plesk => plesk.id === id)
		return pleskInfo
	}

	const digitalInfoEvent = async ({id, value, droplets}: { id?: number, value: DigitalInfo, droplets: any[] }) => {
		// const exist = await findOne({
		//     where: {
		//         type: "digital_ocean",
		//         field: "accounts",
		//         value: JSON.stringify(info)
		//     }
		// });
		// if (exist) {
		//     return;
		// }
		if (id) {
			const result = await updateSetting({
				id,
				type:  'digital_ocean',
				field: 'accounts',
				value: JSON.stringify(value)
			})
			const newAccount = JSON.parse(result.value) as DigitalInfo
			const newUpdate = [...digitalAccounts]
			const indexOf = newUpdate.findIndex(item => item.id === id)
			newUpdate[indexOf] = {id, value, droplets}
			setDigitalAccounts(newUpdate)
		} else {
			const result = await postSetting({
				type:  'digital_ocean',
				field: 'accounts',
				value: JSON.stringify(value)
			})
			const newAccount = JSON.parse(result.value) as DigitalInfo
			const newUpdate = [...digitalAccounts, ...[{
				id:       result.id,
				value:    newAccount,
				droplets: []
			}]]
			setDigitalAccounts(newUpdate)
		}
		setShowCreateNewDialog(false)
	}

	const showModalEvent = (state: boolean) => {
		setShowCreateNewDialog(false)
	}

	const showCreateModal = (): void => {
		setShowCreateNewDialog(true)
	}

	const deleteAccount = (index: number) => {
		const newUpdate = digitalAccounts.filter((account, i) => i !== index)
		setDigitalAccounts(newUpdate)
		const id = digitalAccounts[index].id
		if (id) {
			deleteSettings([id])
		}
	}

	const editAccount = (index: number) => {
		const account = digitalAccounts[index]
		if (!account) {
			return
		}
		setDigitalAccount(account)
		setShowCreateNewDialog(true)

	}
	const [refresh, setRefresh] = useState<number>(0)
	useEffect(() => {
		const getDOAccounts = async () => {
			setLoadingDOAccount(true)
			const result = await getList({
				where: {
					type:  'digital_ocean',
					field: 'accounts',
				}
			})
			if (result.length) {
				const data = result.map(item => ({id: item.id, value: JSON.parse(item.value)}))
				return data
			}
			return []
		}

		getDOAccounts().then(async (data) => {
			if (data) {
				for (const item of data) {
					try {
						// Bổ sung prop droplets vào DOAccounts
						const {droplets} = await getDroplets(item.value.token)
						item.droplets = droplets
					} catch (err) {
						item.droplets = []
					}
				}
				setDigitalAccounts(data)
				getPlesksAccount()
				setLoadingDOAccount(false)

			}
		})
	}, [refresh])


	const genExtra = (index: number, item) => (
		<div>
			<Space>
				<Button
					type="primary"
					onClick={event => {
						event.stopPropagation();
						editAccount(index);
					}}
				>
					Edit
				</Button>
				<Button danger
						onClick={event => {
							event.stopPropagation()
							confirm({
								title:      'Are you sure to delete this <' + item?.value?.name + '> DigitalOcean Account?',
								icon:       <ExclamationCircleOutlined/>,
								content:    'You cannot undo this action',
								okText:     'Yes',
								okType:     'danger',
								cancelText: 'No',
								async onOk() {
									try {
										deleteAccount(index)
									} catch (err) {
										console.log(err)
										message.error('Error: ' + err)
									}
								},
								onCancel() {
								},
							})
						}}
				>
					Delete
				</Button>
			</Space>
		</div>
	)
	const handleCancel_ConnectPlesk = () => {
		setOpenConnectPleskModal(false)
		setPleskUsername(undefined)
		setPleskPassword(undefined)
	}

	const handleSearchSizeAndImage = (
	  confirm: (param?: FilterConfirmProps) => void,
	) => {
		confirm();
	};

	const handleResetSizeAndImageFilter = (clearFilters, confirm) => {
		clearFilters();
		confirm();
	}
  
	const getColumnSearchProps = (dataIndex): any => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
			  <Input
				placeholder={'Search Name'}
				value={selectedKeys[0]}
				onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
				onPressEnter={() => handleSearchSizeAndImage(confirm)}
			  />
			  <Space>
				<Button
				  type="primary"
				  onClick={() => handleSearchSizeAndImage(confirm)}
				  icon={<SearchOutlined />}
				  size="small"
				>
				  Search
				</Button>
				<Button
					onClick={() => clearFilters && handleResetSizeAndImageFilter(clearFilters, confirm)}
					size="small"
					style={{
					width: 90,
					}}
				>
					Reset Filter
				</Button>
				<Button
				  type="link"
				  size="small"
				  onClick={() => {
					close();
				  }}
				>
				  close
				</Button>
			  </Space>
			</div>
		  ),
		  filterIcon: (filtered: boolean) => (
			<SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
		  ),
		  onFilter: (value, record) => {
			return record[dataIndex]
			    .toString()
			    .toLowerCase()
			    .includes((value as string).toLowerCase());
		  },
	});

	const columnsDropletSize: ColumnsType<DropletSize> = [
		{
			title:     'Type',
			key:       'description',
			dataIndex: 'description',
			filters:   [
				{
					text:  'Basic',
					value: 'Basic',
				},
				{
					text:  'General Purpose',
					value: 'General Purpose',
				},
				{
					text:  'CPU-Optimized',
					value: 'CPU-Optimized',
				},
				{
					text:  'Memory-Optimized',
					value: 'Memory-Optimized',
				},
				{
					text:  'Storage-Optimized',
					value: 'Storage-Optimized',
				}
			],
			onFilter:  (value: any, record) => record.description.indexOf(value) === 0,

		},
		{
			title:     'Name',
			key:       'slug',
			dataIndex: 'slug',
			...getColumnSearchProps('slug'),
		},
		{
			title:     'vCPUs',
			key:       'vcpus',
			dataIndex: 'vcpus',
			filters:   [
				{
					text:  '1 vCPUs',
					value: '1',
				},
				{
					text:  '2 vCPUs',
					value: '2',
				},
				{
					text:  '4 vCPUs',
					value: '4',
				},
				{
					text:  '8 vCPUs',
					value: '8',
				},
				{
					text:  '16 vCPUs',
					value: '16',
				},
				{
					text:  '24 vCPUs',
					value: '24',
				},
				{
					text:  '32 vCPUs',
					value: '32',
				},
				{
					text:  '48 vCPUs',
					value: '48',
				},
			],
			onFilter:  (value: any, record) => record.vcpus == value,
			sorter:    (a, b) => a.vcpus - b.vcpus,
			render:    (value) => (value + ' vCPUs'),
		},
		{
			title:     'Memory',
			key:       'memory',
			dataIndex: 'memory',
			filters:   [
				{
					text:  '0.5 GB',
					value: '512',
				},
				{
					text:  '1 GB',
					value: '1024',
				},
				{
					text:  '2 GB',
					value: '2048',
				},
				{
					text:  '4 GB',
					value: '4096',
				},
				{
					text:  '8 GB',
					value: '8192',
				},
				{
					text:  '16 GB',
					value: '16384',
				},
				{
					text:  '32 GB',
					value: '32768',
				},
				{
					text:  '64 GB',
					value: '65536',
				},
				{
					text:  '96 GB',
					value: '98304',
				},
				{
					text:  '128 GB',
					value: '131072',
				},
				{
					text:  '160 GB',
					value: '163840',
				},
				{
					text:  '192 GB',
					value: '196608',
				},
				{
					text:  '256 GB',
					value: '262144',
				},

			],
			onFilter:  (value: any, record) => record.memory == value,
			sorter:    (a, b) => a.memory - b.memory,
			render:    (value) => (value / 1024 + ' GB')

		},
		{
			title:     'SSD',
			key:       'disk',
			dataIndex: 'disk',
			sorter:    (a, b) => a.disk - b.disk,
			render:    (value) => (value + ' GB')

		},
		{
			title:     'Transfer',
			key:       'transfer',
			dataIndex: 'transfer',
			sorter:    (a, b) => a.transfer - b.transfer,
			render:    (value) => (value + ' TB')

		},
		{
			title:     'Price Monthly',
			key:       'price_monthly',
			dataIndex: 'price_monthly',
			sorter:    (a, b) => a.price_monthly - b.price_monthly,
			render:    (value) => ('$' + value + '/mo')

		},
		{
			title:     'Price Hourly',
			key:       'price_hourly',
			dataIndex: 'price_hourly',
			sorter:    (a, b) => a.price_hourly - b.price_hourly,
			render:    (value) => ('$' + value + '/hr')

		},
		{
			title:     'Available',
			key:       'available',
			dataIndex: 'available',
			render:    (value) => value ? 'True' : 'False'

		},

	]
	const columnsDropletImage: ColumnsType<DropletImage> = [
		{
			title:     'ID',
			key:       'id',
			dataIndex: 'id',
		},
		{
			title:     'Name',
			key:       'name',
			dataIndex: 'name',
			filters:   [
				{
					text:  'Plesk',
					value: 'Plesk',
				},

			],
			...getColumnSearchProps('name'),
		},
		{
			title:     'Distribution',
			key:       'distribution',
			dataIndex: 'distribution',
			filters:   [
				{
					text:  'Ubuntu',
					value: 'Ubuntu',
				},
				{
					text:  'RancherOS',
					value: 'RancherOS',
				},
				{
					text:  'CentOS',
					value: 'CentOS',
				},
				{
					text:  'Debian',
					value: 'Debian',
				},
				{
					text:  'Fedora',
					value: 'Fedora',
				},
				{
					text:  'Rocky Linux',
					value: 'Rocky Linux',
				},
			],
			onFilter:  (value: any, record) => record.distribution === value,
		},
		{
			title:     'Slug',
			key:       'slug',
			dataIndex: 'slug',
			filters:   [
				{
					text:  'plesk',
					value: 'plesk',
				},

			],
			onFilter:  (value: any, record) => record?.slug?.indexOf(value) === 0,

		},
		{
			title:     'Public',
			key:       'public',
			dataIndex: 'public',
			filters:   [
				{
					text:  'True',
					value: true,
				},
				{
					text:  'False',
					value: false,
				}
			],
			onFilter:  (value: any, record) => record.public === value,
			render:    (value) => value ? 'True' : 'False'
		},
		// {
		//     title: 'Regions',
		//     key: 'regions',
		//     dataIndex: 'regions',

		// },
		{
			title:     'Min Disk Size',
			key:       'min_disk_size',
			dataIndex: 'min_disk_size',
			sorter:    (a, b) => a.min_disk_size - b.min_disk_size,
			render:    (value) => value + ' GB'

		},
		{
			title:     'Description',
			key:       'description',
			dataIndex: 'description',
			filters:   [
				{
					text:  'Plesk',
					value: 'Plesk',
				},

			],
			onFilter:  (value: any, record) => record?.description?.indexOf(value) === 0,
		},
		{
			title:     'Size Gigabytes',
			key:       'size_gigabytes',
			dataIndex: 'size_gigabytes',
			sorter:    (a, b) => a.size_gigabytes - b.size_gigabytes,
			render:    (value) => value + ' GB'

		},
		{
			title:     'Type',
			key:       'type',
			dataIndex: 'type',
			filters:   [
				{
					text:  'snapshot',
					value: 'snapshot',
				},
				{
					text:  'backup',
					value: 'backup',
				},

			],
			onFilter:  (value: any, record) => record.type === value,
		},
		{
			title:     'Status',
			key:       'status',
			dataIndex: 'status',
		},


	]
	const handleCancel_CreateDroplet = () => {
		setOpenCreateDropletModal(false)
		setNewDropletName(undefined)
		setSelectedDropletImages([])
		setSelectedDropletSizes([])
	}

	interface TableParams {
		pagination?: TablePaginationConfig;
		sortField?: string;
		sortOrder?: string;
		filters?: Record<string, FilterValue | null>;
	}

	const [tableDropletSizeParams, setTableDropletSizeParams] = useState<TableParams>({
		pagination: {
			current:  1,
			pageSize: 10,
		},
	})
	const [tableDropletImageParams, setTableDropletImageParams] = useState<TableParams>({
		pagination: {
			current:  1,
			pageSize: 10,
		},
	})
	const fetchListDropletSizes = async (page: number, per_page: number, token: string) => {
		setLoadingDropletSize(true)
		await ApiService.getWithParams('/Vps/getListDropletSizes', {page, per_page, token}).then(res => {
			setListDropletSizes(res.data.result.sizes)
			setTableDropletSizeParams({
				...tableDropletSizeParams,
				pagination: {
					...tableDropletSizeParams.pagination,
					total: res?.data?.result?.meta?.total || 0,
				}
			})
		})
		setLoadingDropletSize(false)
	}
	const fetchListDropletImages = async (page: number, per_page: number, token: string) => {
		setLoadingDropletImage(true)
		await ApiService.getWithParams('/Vps/getListDropletImages', {page, per_page, token}).then(res => {
			setListDropletImages(res.data.result.images)
			setTableDropletImageParams({
				...tableDropletImageParams,
				pagination: {
					...tableDropletImageParams.pagination,
					total: res?.data?.result?.meta?.total || 0
				}
			})
		})
		setLoadingDropletImage(false)
	}

	const [currentToken, setCurrentToken] = useState<string>('')
	const [currentPageDropletSize, setCurrentPageDropletSize] = useState<number>(1)
	const [perPageDropletSize, setPerPageDropletSize] = useState<number>(10)
	const [currentPageDropletImage, setCurrentPageDropletImage] = useState<number>(1)
	const [perPageDropletImage, setPerPageDropletImage] = useState<number>(10)
	const handleCreateDroplet = async (token: string) => {
		setCurrentToken(token)
		setOpenCreateDropletModal(true)
		await Promise.all([
			fetchListDropletSizes(currentPageDropletSize, perPageDropletSize, token),
			fetchListDropletImages(currentPageDropletImage, perPageDropletImage, token)
		])
	}
	const [selectedDropletSizes, setSelectedDropletSizes] = useState<DropletSize[]>()
	const [selectedDropletImages, setSelectedDropletImages] = useState<DropletImage[]>()
	const [loadingDropletSize, setLoadingDropletSize] = useState<boolean>(false)
	const [loadingDropletImage, setLoadingDropletImage] = useState<boolean>(false)
	useEffect(() => {
		fetchListDropletSizes(currentPageDropletSize, perPageDropletSize, currentToken)
	}, [JSON.stringify(tableDropletSizeParams)])
	useEffect(() => {
		fetchListDropletImages(currentPageDropletImage, perPageDropletImage, currentToken)
	}, [JSON.stringify(tableDropletImageParams)])


	const handleTableDropletSizeChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<DropletSize> | SorterResult<DropletSize>[],
		extra: TableCurrentDataSource<DropletSize>
	) => {
		setTableDropletSizeParams({
			pagination,
			filters,
			...sorter,
			...extra
		})
		setCurrentPageDropletSize(pagination.current || 1)
		setPerPageDropletSize(pagination.pageSize || 10)
	}
	const handleTableDropletImageChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<DropletImage> | SorterResult<DropletImage>[],
		extra: TableCurrentDataSource<DropletImage>
	) => {
		setTableDropletImageParams({
			pagination,
			filters,
			...sorter,
			...extra
		})
		setCurrentPageDropletImage(pagination.current || 1)
		setPerPageDropletImage(pagination.pageSize || 10)
	}

	const rowSelectionDropletSizes = {
		onChange: (selectedRowKeys: React.Key[], selectedRows: DropletSize[]) => {
			console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
			setSelectedDropletSizes(selectedRows)
		}
	}
	const rowSelectionDropletImages = {
		onChange: (selectedRowKeys: React.Key[], selectedRows: DropletImage[]) => {
			console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
			setSelectedDropletImages(selectedRows)
		}
	}
	const runCreateDroplet = async () => {
		if (!newDropletName) {
			message.error('Please input new droplet name.')
			return
		}
		if (!selectedDropletSizes) {
			message.error('Please select droplet size.')
			return
		}
		if (!selectedDropletImages) {
			message.error('Please select droplet image.')
			return
		}

		const body = {
			name:  newDropletName,
			image: selectedDropletImages[0].slug,
			size:  selectedDropletSizes[0].slug
		}

		confirm({
			title:      'Are you sure to create new droplet?',
			icon:       <ExclamationCircleOutlined/>,
			content:    'You cannot undo this action',
			okText:     'Yes',
			okType:     'danger',
			cancelText: 'No',
			async onOk() {
				try {
					await ApiService.post('/Vps/createDroplet', {body, token: currentToken}).then(res => {
						if (res.data.result.code === 1) {
							message.success('Create new droplet successfully.', 10)
							setOpenCreateDropletModal(false)
							setRefresh(pre => pre + 1)
						} else {
							message.error('Fail to create new droplet. ' + res.data.result.error.message, 10)
						}
					})
				} catch (err) {
					console.log(err)
					message.error('Error: ' + err)
				}
			},
			onCancel() {
			},
		})
	}
	const [pleskUsername, setPleskUsername] = useState<string>()
	const [pleskPassword, setPleskPassword] = useState<string>()

	const [activeTabKey, setActiveTabKey] = useState('do-acc')
	const [showAddDirectPleskModal, setShowAddDirectPleskModal] = useState(false)
	const [directPleskItems, setDirectPleskItems] = useState<any>([])
	const [fetchDirectPleskCount, setFetchDirectPleskCount] = useState<number>(0)
	const [addDirectPleskForm] = Form.useForm()
	const refreshDirectPlesk = () => {
		setFetchDirectPleskCount(fetchDirectPleskCount + 1)
	}
	useEffect(() => {
		(async () => {
			// lấy direct plesk items từ Setting
			const result = await getList({
				where: {
					type:  'plesk',
					field: 'vps',
				},
			})
			if (!result) return

			const newTableItems: any = []
			for (const item of result) {
				const {id, type, field} = item
				try {
					newTableItems.push({
						id, type, field,
						...JSON.parse(item.value)
					})
				} catch {
				}
			}
			setDirectPleskItems(newTableItems)
		})()
	}, [fetchDirectPleskCount])

	const directPleskColumns: (Column | any)[] = [
		{title: 'ID', key: 'id', dataIndex: 'id'},
		{
			title:     'NAME',
			key:       'name',
			dataIndex: 'name',
			editable:  true,
		},
		{
			title:     'IP Address',
			key:       'ip',
			dataIndex: 'ip',
			editable:  true,
			render:    (_, record) => {
				const apiEndpoint = record.ip ? 'https://' + record.ip : ''
				return (<a href={apiEndpoint} target="_blank">{apiEndpoint}</a>)
			}
		},
		{
			title:  'ACTION',
			key:    'action',
			render: (_, record) => (<Space direction="horizontal">
					<Button type="primary" onClick={() => {
						// giả data cho giống digital ocean
						setCurrentVPS({
							networks: {
								v4: [{
									type:       'public',
									ip_address: record.ip,
								}]
							}
						})
						setOpenConnectPleskModal(true)
					}}>Connect</Button>
					<Button type="default" onClick={async () => {
						confirm({
							title:      `Delete plesk vps ${record.name} from domainlabs ?`,
							icon:       <ExclamationCircleOutlined/>,
							okText:     'Yes',
							okType:     'danger',
							cancelText: 'No',
							async onOk() {
								console.log('record', record)
								try {
									await deleteSettings([record.id])
									refreshDirectPlesk()
								} catch (err) {
									message.error('Error: ' + err)
								}
							},
							onCancel() {
							},
						})
					}} danger>Delete</Button>
				</Space>
			),
		},
	]

	const tabsItems: TabsProps['items'] = [
		{
			key:   'do-acc',
			label: `DigitalOcean Accounts`,
			// @ts-ignore
			children: (<>
				{!readOnly && <div style={{paddingBottom: 8}}>
                    <Button type="primary" onClick={showCreateModal}>
                        Create DigitalOcean Account
                    </Button>
                </div>
				}
				<div>
					<Collapse onChange={onChange}>
						{
							digitalAccounts && digitalAccounts.map((item, index) =>
								(
									<Panel extra={readOnly ? '' : genExtra(index, item)} header={`${index + 1}. ${item.value.name}`} key={index}>
										<Space direction="vertical">

											<Button type="primary" onClick={() => handleCreateDroplet(item.value.token)}>Create Droplet</Button>
											<Table
												rowKey="id"
												columns={columns}
												dataSource={item.droplets}
											/>
										</Space>
									</Panel>
								)
							)
						}
					</Collapse>
				</div>
			</>),
		},
		{
			key:      'direct-plesk',
			label:    `Direct Plesk`,
			children: (<>
				<Space direction="vertical" style={{width: '100%'}}>
					<Button type="primary" onClick={() => {
						addDirectPleskForm.resetFields()
						setShowAddDirectPleskModal(true)
					}}>Add Plesk VPS</Button>
					<Table
						rowKey="id"
						columns={directPleskColumns}
						dataSource={directPleskItems}
					/>
				</Space>
			</>),
		},
	]

	return (
		<Spin tip="Loading..." size="large" spinning={loadingDOAccount}>
			<Tabs defaultActiveKey={activeTabKey} items={tabsItems} onChange={(key) => setActiveTabKey(key)}/>

			<CreateDOModal
				showModal={showCreateNewDialog}
				showModalEvent={showModalEvent}
				digitalInfo={digitalAccount}
				digitalInfoEvent={digitalInfoEvent}
			/>
			<Modal
				destroyOnClose
				open={openCreateDropletModal}
				title="Create Droplet"
				onOk={runCreateDroplet}
				onCancel={handleCancel_CreateDroplet}
				width={1500}
				style={{top: 20}}

			>
				<Form
					// labelCol={{ span: 6 }}
					// wrapperCol={{ span: 16 }}
				>
					<Form.Item name="name" label="Name" rules={[{required: true}]}>
						<Input value={newDropletName} onChange={e => setNewDropletName(e.target.value)}/>
					</Form.Item>
					<Typography.Title level={4}>Size:</Typography.Title>
					<Spin size="large" spinning={loadingDropletSize}>
						<Table
							rowKey="slug"
							rowSelection={{
								type: 'radio',
								...rowSelectionDropletSizes,
							}}
							columns={columnsDropletSize}
							dataSource={listDropletSizes}
							pagination={tableDropletSizeParams.pagination}
							rowClassName="editable-row"
							onChange={handleTableDropletSizeChange}
						/>
					</Spin>
					<Typography.Title level={4}>Image: </Typography.Title>
					<Spin size="large" spinning={loadingDropletImage}>
						<Table
							rowKey="id"
							rowSelection={{
								type: 'radio',
								...rowSelectionDropletImages,
							}}
							columns={columnsDropletImage}
							dataSource={listDropletImages}
							pagination={tableDropletImageParams.pagination}
							rowClassName="editable-row"
							onChange={handleTableDropletImageChange}
						/>
					</Spin>
				</Form>
			</Modal>
			<Modal
				destroyOnClose
				open={openConnectPleskModal}
				title="Connect Plesk"
				onOk={runConnectPlesk}
				onCancel={handleCancel_ConnectPlesk}
				width={500}
				style={{top: 20}}

			>
				<Form
					labelCol={{span: 6}}
					wrapperCol={{span: 16}}
				>
					<Form.Item name="username" label="Username" rules={[{required: true}]}>
						<Input value={pleskUsername} onChange={e => setPleskUsername(e.target.value)}/>
					</Form.Item>
					<Form.Item name="password" label="Password" rules={[{required: true}]}>
						<Input.Password value={pleskPassword} onChange={e => setPleskPassword(e.target.value)}/>
					</Form.Item>
				</Form>
			</Modal>


			<Modal
				destroyOnClose
				open={showAddDirectPleskModal}
				title="Add Direct Plesk"
				onOk={() => {
					addDirectPleskForm
						.validateFields()
						.then(async (values) => {
							addDirectPleskForm.resetFields()
							values.ip = values.ip.trim()
							await postSetting({
								type:  'plesk',
								field: 'vps',
								value: JSON.stringify(values),
							})
							refreshDirectPlesk()
							console.log('addDirectPleskForm values', values)
						})
						.catch((err) => {
							console.log('addDirectPleskForm Failed:', err)
							message.error('Error: ' + err)
						})
					setShowAddDirectPleskModal(false)
				}}
				onCancel={() => {
					setShowAddDirectPleskModal(false)
				}}
				width={500}
				style={{top: 20}}
			>
				<Form
					form={addDirectPleskForm}
					labelCol={{span: 6}}
					wrapperCol={{span: 16}}
				>
					<Form.Item name="name" label="Name" rules={[{required: true}]}>
						<Input/>
					</Form.Item>
					<Form.Item name="ip" label="IP Address" rules={[
						{required: true},
						{
							validator: (rule: any, value: string, cb: (msg?: string) => void) => {
								/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/.test(value) ? cb() : cb('not an ip')
							}
						}
					]}>
						<Input/>
					</Form.Item>
				</Form>
			</Modal>

		</Spin>
	)
}

export default DigitalOceans
