import { updateSetting, postSetting, findOne, getList } from "@/pages/Settings/services/setting-service"
import { DomainPlesk, Filter } from "@/types";
import { isValidJson } from "@/util/handle-string";
import axios from "axios";

export const saveListDomainChecked = async (vpsIP: string, data: { id: number, name: string }[]): Promise<void> => {
    try {
        const recordExist = await findOne({
            where: {
                type: 'status',
                field: 'domains-checked',
            }
        })
        if (recordExist) {
            const curData = JSON.parse(recordExist.value || '{}')
            curData[vpsIP] = data
            // clean up vps with empty domains
            for(const ip of Object.keys(curData)) {
                if(curData[ip].length === 0) delete curData[ip]
            }
            updateSetting({
                id: recordExist.id,
                type: 'status',
                field: 'domains-checked',
                value: JSON.stringify(curData),
            })
        } else {
            postSetting({
                type: 'status',
                field: 'domains-checked',
                value: JSON.stringify({[vpsIP]: data}),
            })
        }
    } catch (error) {
        console.log('error', error);
    }
}

export const getListDomainChecked = async (vpsIP: string) => {
    try {
        const response = await findOne({
            where: {
                type: 'status',
                field: 'domains-checked',
            }
        })
        const data: DomainPlesk[] = JSON.parse(response.value || '{}');
        return data[vpsIP] || [];
    } catch (error) {
        console.log('error', error);

    }
}

export const getLogs = async (filter: Filter) => {
    try {
        const { data } = await axios.get('/Logs', {
            params: {
                filter,
            },
        });
        if (!data) {
            return [];
        }
        const result = data.map((item, index) => {
            return {
                key: index + 1,
                date: item.date,
                name: item.name,
                type: item.type,
                value: isValidJson(item.value) ? JSON.parse(item.value) :  [],
            }
        })
        return result;
    } catch (error) {
        throw new Error(error);
    }
};

export const getLogsCount = async (filter: Filter) => {
    try {
        const { data } = await axios.get('/Logs/count', {
            params: {
                where: filter.where,
            },
        });
        return data?.count || 0;
    } catch (error) {
        throw new Error(error);
    }
};

// status code check
export const saveStatusCodeCheck = async (data: string[]): Promise<void> => {
    try {
        const recordExist = await findOne({
            where: {
                type: 'status',
                field: 'status-codes-checked',
            }
        })
        if (recordExist) {
            updateSetting({
                id: recordExist.id,
                type: 'status',
                field: 'status-codes-checked',
                value: JSON.stringify(data),
            })
            return;
        }
        postSetting({
            type: 'status',
            field: 'status-codes-checked',
            value: JSON.stringify(data),
        })
    } catch (error) {
        console.log('error', error);
    }
}

export const getStatusCodeCheck = async () => {
    try {
        const response = await findOne({
            where: {
                type: 'status',
                field: 'status-codes-checked',
            }
        })
        const data: string[] = JSON.parse(response.value || '[]');
        return data;
    } catch (error) {
        console.log('error', error);
        return [];

    }
}
