const configs: Record<string, Record<string, any>> = {
    production:  {
        // BASE_URL: 'http://localhost:4400/api',
        // BASE_URL: 'http://163.172.176.159:3000/api',
        BASE_URL: 'https://domainlabs-api.pml-tech.com/api',
    },
    development: {
        // BASE_URL: 'http://localhost:4400/api',
        // BASE_URL: 'http://163.172.176.159:3000/api',
        BASE_URL: 'https://domainlabs-api.pml-tech.com/api',

    }
}


export const cms_version = '1.8';
export default Object.assign({}, configs[import.meta.env.MODE], {env: import.meta.env})
