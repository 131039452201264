import axios from 'axios';
import { Cloudflare } from '../types';

type Filter = {
    skip?: number;
    limit?: number;
    where?: Record<string, any>;
}

export const getList = async (filter: Filter = {}) => {
    try {
        const { data } = await axios.get('/Cloudflares', {
            params: {
                filter,
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getCount = async (filter: Filter) => {
    try {
        const { data } = await axios.get('/Cloudflares/count', {
            params: {
                where: filter.where,
            },
        });
        return data?.count || 0;
    } catch (error) {
        throw new Error(error);
    }
};

export const findOne = async (filter: Filter = {}) => {
    try {
        const { data } = await axios.get('/Cloudflares/findOne', {
            params: {
                filter,
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const updateCloudflare = async (input: Cloudflare) => {
    try {
        const { data } = await axios.patch('/Cloudflares', input);
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const postCloudflare = async (input: Cloudflare) => {
    try {
        const { data } = await axios.post('/Cloudflares', input);
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const deleteCloudflare = async (ids: number[]) => {
    try {
        await Promise.all(ids.map((id: number) => axios.delete(`/Cloudflares/${id}`)));
    } catch (error) {
        throw new Error(error);
    }
};
