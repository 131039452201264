import ApiService from '@/services/api.service';
import { Space, Table, Button, message, Modal, Spin, Typography, Select, AutoComplete, Result, Tabs, Input, Form } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useEffect, useRef, useState } from 'react';
import PleskDetails from './components/plesks-details';
import { useLocation } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { BackupTemplate, Domain, DomainPlesk, SubDomain, UploadedPlugin, UploadedTheme, WordpressSite } from '@/types';
import { PluginsThemes } from '../Settings/types';
import { useNavigate } from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { ColumnType } from 'antd/lib/table';
import Highlighter from 'react-highlight-words';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import PleskStatusModal from './components/plesk-status-modal';
import { getListDomainChecked,  saveListDomainChecked } from './components/Status/services/status-services';

type DataIndex = keyof DomainPlesk;
type Props = {};

const { Option } = Select;
const { Title } = Typography;
const { TabPane } = Tabs;

const Plesks = (props: Props) => {
  const navigate = useNavigate();
  const { state } = useLocation()
  const { vpsIP } :any = state;

  const [selectedWordpressSites, setSelectedWordpressSites] = useState<WordpressSite[]>([]);
  const [openListWordpressModal, setOpenListWordpressModal] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const [allowCommentStatus, setAllowCommentStatus] = useState<boolean>(false);
  const [domain, setDomain] = useState<string>('');
  const [blogname, setBlogname] = useState<string>('');
  const [domainItem, setDomainItem] = useState<DomainPlesk>();
  const [listDomain, setListDomain] = useState<Domain[]>([]);
  const [listDomains, setListDomains] = useState<DomainPlesk[]>([]);
  const [allDomains, setAllDomains] = useState<DomainPlesk[]>([]);
  const [listSubDomains, setListSubDomains] = useState<SubDomain[]>([]);
  const [listWordpress, setListWordpress] = useState<WordpressSite[]>([]);
  const [loadingData, setLoadingData] = useState(true);
  // list plugins, themes of WP-Instance
  const [pluginsAndThemes, setPluginsAndThemes] = useState<PluginsThemes | Record<string, never>>({});
  // state when active theme and waiting clear cache successfully!
  const [loadingState, setLoadingState] = useState<boolean>(false);

  //Handle Modal
  const [domainName, setDomainName] = useState<string>('');
  const [selectedThemes, setSelectedThemes] = useState<UploadedTheme[]>([]);
  const [loadingThemes, setLoadingThemes] = useState(true);
  const [listUploadedThemes, setListUploadedThemes] = useState<UploadedTheme[]>([]);
  const [listUploadedPlugins, setListUploadedPlugins] = useState<UploadedPlugin[]>([]);
  const [selectedPlugins, setSelectedPlugins] = useState<UploadedPlugin[]>([]);
  const [selectedSubDomains, setSelectedSubDomains] = useState<SubDomain[]>([]);
  const [loadingPlugins, setLoadingPlugins] = useState(true);
  const [open, setOpen] = useState(false);
  const [openAddSubDomainModal, setOpenAddSubDomainModal] = useState(false);
  const [openCreateWebsiteModal, setOpenCreateWebsiteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openFleskThemesAndPluginsModal, setOpenFleskThemesAndPluginsModal] = useState(false);
  const [backupTemplateList, setBackupTemplateList] = useState<BackupTemplate[]>([]);
  const [restoreTemplateList, setRestoreTemplateList] = useState<BackupTemplate[]>([]);
  const [loadingBackupTemplateList, setLoadingBackupTemplateList] = useState<boolean>(false);
  const getBackupTemplateList = async (domainName: string, filter?: object) => {
    try {
        setLoadingBackupTemplateList(true);
        // const res = await ApiService.query('/BackupTemplates', {where:{backupFromDomain: domainName}});
        if(filter){
          const res = await ApiService.query('/BackupTemplates', filter );
          setBackupTemplateList(res.data)
          setLoadingBackupTemplateList(false);
        }else{
          const res = await ApiService.query('/BackupTemplates', {where:{}} );
          setRestoreTemplateList(res.data)
          setLoadingBackupTemplateList(false);
        }
    } catch (err) {
        setLoadingBackupTemplateList(false);
        message.error(err, 10);
        console.error(err);
    }
}

  const showModal = (record: DomainPlesk) => {
    setOpen(true);
    setDomainName(record.name);
  };
  const showModalAddSubDomain = (record: DomainPlesk) => {
    setOpenAddSubDomainModal(true);
    handleChangeDomain(record.name);
    setDomainItem(record);

  };
  const showCreateWebsiteModal = () => {
    setOpenCreateWebsiteModal(true);
  };
  const handleOk = (domainName: string, selectedPlugins: UploadedPlugin[], selectedThemes: UploadedTheme[]) => {

    setLoading(true);
    handleInstallWordpress(domainName, selectedPlugins, selectedThemes);
    setLoading(false);
    // setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setBlogname('');
  };
  const handleCancelListWordpressModal = () => {
    setOpenListWordpressModal(false);
  };
  const handleCreateWebsite = async (domainName: string, selectedPlugins: UploadedPlugin[], selectedThemes: UploadedTheme[]) => {


    confirm({
      title: 'Are you sure to create website?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {
          const res = await handleCreateDomain(domainName);
          // Nếu tạo domain thành công thì mới cài đặt wordpress
          if (res) {
            await handleInstallWordpress_WithoutConfirm(domainName, selectedPlugins, selectedThemes);
            setOpenCreateWebsiteModal(false);
          }

        } catch (err) {
          console.log(err);
          message.error('Error: ' + err);
        }
      },
      onCancel() {
      },
    });



  };

  const handleCancelCreateWebsite = () => {
    setBlogname('');
    setOpenCreateWebsiteModal(false);
  };
  const handleCancelAddSubdomain = () => {
    setOpenAddSubDomainModal(false);
  };
  const rowSelectionSubDomains = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: SubDomain[]) => {
      setSelectedSubDomains(selectedRows);
    },
    getCheckboxProps: (record: SubDomain) => {
      const temp = allDomains.filter(e => e.base_domain_id === record.id);
      const listDomainCreated = allDomains.map(e => e.name);
      return ({
        disabled: listDomainCreated.includes(record.name), // Column configuration not to be checked
        name: record.name,
      })
    },
  };
  const rowSelectionUploadedPlugins = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: UploadedPlugin[]) => {
      setSelectedPlugins(selectedRows);
      console.log(selectedRows);


    }
  };
  const rowSelectionUploadedThemes = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: UploadedTheme[]) => {
      setSelectedThemes(selectedRows);
      console.log(selectedRows);
    }
  };
  const columnsUploadedPlugins: ColumnsType<UploadedPlugin> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: !openCreateWebsiteModal?'Action':'',
      key: 'action',
      render: (record) =>(!openCreateWebsiteModal && <Button danger onClick={() => handleRemoveUploadedPluginPlesk(record)}>Remove</Button>)
    }
  ];
  const columnsSubDomains: ColumnsType<SubDomain> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    },
  ];
  const columnsUploadedThemes: ColumnsType<UploadedTheme> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: !openCreateWebsiteModal?'Action':'',
      key: 'action',
      render: (record) => (!openCreateWebsiteModal && <Button danger onClick={() => handleRemoveUploadedThemePlesk(record)}>Remove</Button>)
    }
  ];
  const handleRemoveUploadedPluginPlesk = async (plugin: UploadedPlugin) => {
    confirm({
      title: 'Are you sure to remove this plugin?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try{
          const res = await ApiService.post('/Domains/removeUploadedPluginPlesk',{pluginId: Number.parseInt(plugin.id), pluginVersion: plugin.version, infoEncoded: state});
          console.log(res);
          if(res?.data?.result?.stdout){
            message.success("Remove the plugin "+ plugin.name + " with version "+ plugin.version + " successfully!",5);
            setLoadingPlugins(true);
            ApiService.getWithParams('/Domains/getAllUploadedPlugins', { infoEncoded: state }).then(res => {
              setListUploadedPlugins(res.data.result);
              setLoadingPlugins(false);
            })
          }else{
            message.error(res?.data?.result?.stderr,10);
          }
        }catch(err){
          message.error(err);
        }
      },
      onCancel() {
      },
    });


  }
  const handleRemoveUploadedThemePlesk = async (theme: UploadedTheme) => {
    confirm({
      title: 'Are you sure to remove this theme?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try{
          const res = await ApiService.post('/Domains/removeUploadedThemePlesk',{themeId: Number.parseInt(theme.id), themeVersion: theme.version, infoEncoded: state});
          console.log(res);
          if(res?.data?.result?.stdout){
            message.success("Remove the theme "+ theme.name + " with version "+ theme.version + " successfully!",5);
            setLoadingThemes(true);
            ApiService.getWithParams('/Domains/getAllUploadedThemes', { infoEncoded: state }).then(res => {
              setListUploadedThemes(res.data.result);
              setLoadingThemes(false);
            })
          }else{
            message.error(res?.data?.result?.stderr,10);
          }
        }catch(err){
          message.error(err);
        }
      },
      onCancel() {
      },
    });


  }
  const fetchListPluginsAndThemes = async () => {
    Promise.all([
      ApiService.getWithParams('/Domains/getAllUploadedPlugins', { infoEncoded: state }).then(res => {
        setListUploadedPlugins(res.data.result);
        setLoadingPlugins(false);
      }),
      ApiService.getWithParams('/Domains/getAllUploadedThemes', { infoEncoded: state }).then(res => {
        setListUploadedThemes(res.data.result);
        setLoadingThemes(false);
      })
    ])
  }
  const fetchListDomainOnGoDaddy = async () => {
    await ApiService.get('/Domains/getListAllRegisteredDomains_On_Godaddy')
      .then(res => {
        setListDomain(res.data.result)
      });
  }
  useEffect(() => {
    fetchListPluginsAndThemes();
    fetchListDomainOnGoDaddy();

  }, []);
  //End - Handle Modal

  const domainsList = async () => {

    let newListDomainChecked = [] ;

    try {
      await ApiService.getWithParams('/Domains/getPleskDomains', {
        infoEncoded: state,
      }).then(({ data }) => {
        // filter main domain
        const list = data.result.filter(e => e.base_domain_id === 0);
        setListDomains(list);
        setAllDomains(data.result);
        newListDomainChecked = data.result.map(e => {
          let object = {id: e.id, name: e.name}
          return object
        })
      });
      // Cập nhật danh sách list domain cần check status là tất cả domain có trong plesk server.
      // All domains should be in the cron for each server
      await saveListDomainChecked(vpsIP,newListDomainChecked);
    } catch (err) {
      setLoadingData(false);
      // message.error('The Username or Password is Incorrect. Try again', 10);
      navigate("/admin");

    }
 


  };


  const wpInstances = async () => {
    try {
      await ApiService.getWithParams('/Domains/getPleskWPInstances', {
        infoEncoded: state,
      }).then(({ data }) => setListWordpress(data.result));
    } catch (err) {
      setLoadingData(false);
    }

  };


  const fetchData = async () => {
    setLoadingData(true);
    await Promise.all([domainsList(), wpInstances()]);
    setLoadingData(false);
  }

  const { confirm } = Modal;

  const handleDeleteDomain = (record) => {
    confirm({
      title: 'Are you sure to delete this domain?',
      icon: <ExclamationCircleOutlined />,
      content: 'Delete this domain also delete all installed wordpress sites on this domain. You cannot undo this action',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {
          await ApiService.post('/Domains/deleteDomain', { id: record.id, infoEncoded: state })
            // Delete Zone on Cloudflare , delete all dns record for specified zone
            const zoneId = await getZoneIdByDomainName(record.name);
            if(zoneId && zoneId.length){
              await ApiService.post('./Domains/deleteAllDNSRecordForSpecifiedZone',{zoneId}).then(res => {
                console.log('delete all dns record for specified zone successfully',res)
              });
              await ApiService.post('./Domains/deleteZone',{id: zoneId}).then(res => {
                console.log('delete zone successfully',res)
              });
            }else{
              // delete DNS record of subdomain
              await ApiService.post('./Domains/deleteDNSRecordOfSubDomain',{subDomain: record.name}).then(res => {
                console.log('delete dns record for subdomain successfully',res)
              });
            }
            await fetchData().then(res => {
              message.success('Domain is deleted!')
            });
        } catch (err) {
          console.log(err);
          message.error('Error: ' + err);
        }
      },
      onCancel() {
      },
    });
  };
  const handleAddSubDomains = async (records: SubDomain[]) => {
    if (!records?.length) {
      message.warning('Please choose Subdomain!');
      return;
    }
    confirm({
      title: 'Are you sure to created those subdomains?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {
          const listSubdoms = records.map(e => e.prefix);
          const list_subdomain = selectedSubDomains.map(e => e.name);
          const content = vpsIP;
          const type = "A";
          if (domainItem) {
            const zoneId = await getZoneIdByDomainName(domainItem.name);
            await handleCreateListSubDomain(listSubdoms, domainItem.name, 'httpdocs');
            if(zoneId && zoneId.length) await handleCreateListDNSRecord(zoneId, type, list_subdomain, content, true);
            setOpenAddSubDomainModal(false);
            setSelectedSubDomains([]);
            await fetchData();
          }
        } catch (err) {
          console.log(err);
          setOpenAddSubDomainModal(false);
          message.error('Error: ' + err);
        }
      },
      onCancel() {
      },
    });
  };
  const handleDeleteWordpress = (record) => {
    confirm({
      title: 'Are you sure to delete this wordpress site?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {
          await ApiService.post('/Domains/removeWordpress', { id: record.id.toString(), infoEncoded: state }).then(async res => {
            await fetchData().then(res => {
              message.success('Wordpress site is deleted!')
            });

          });

        } catch (err) {
          console.log(err);
          message.error('Error: ' + err);
        }
      },
      onCancel() {
      },
    });
  };
  const handleCreateBackupFile = (instanceId : number, domainName : string) => {
    confirm({
      title: 'Are you sure to create backup template?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action. Please wait about 10-15 minutes!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {
          //begin - Delete domain "createbackuptemplate.com" if exist
          await ApiService.post('/Domains/deletePleskDomainByName', { domainName: "createbackuptemplate.com", infoEncoded: state }).then(async res => {
          });
          //end - Delete domain "createbackuptemplate.com" if exist

          //begin - create temporary domain and clone this domain to it
          let targetDomainName = "createbackuptemplate.com";
          let body = {
            "name": targetDomainName,
            "hosting_type": "virtual",
            "hosting_settings": {
              "ftp_login": targetDomainName + "_ftp",
              "ftp_password": "changeme1Q@123XYZ"
            }
          }
          let targetDomainId : number = 0;
      
          await ApiService.post('/Domains/createDomain', { body, infoEncoded: state }).then(async res => {
              if(res?.data?.result?.data?.id){
                targetDomainId = res.data.result.data.id;
                message.success('create temporary domain "' + targetDomainName + '" successfully!');
              }
          });
          let targetInstanceId : number = 0;
          try{
            await ApiService.post('/Domains/cloneWordpress', {sourceInstanceId: instanceId, targetDomainName, infoEncoded: state }).then(async res => {
              if(res?.data?.result?.stdout){
                let temp = JSON.parse(res.data.result.stdout);
                targetInstanceId = temp.targetInstanceId;
              }
              message.success('clone to domain "' + targetDomainName + '" successfully!');
            });
          }catch(err){
            console.log(err);
            while(!targetInstanceId){
              await ApiService.getWithParams('/Domains/getWordpressInstanceIdOfDomain', { domainId: targetDomainId ,infoEncoded : state}).then(res => {
                targetInstanceId = res.data.result;
                console.log('viet targetInstanceId',targetInstanceId);
              })
            }
            
          }
          

          if(!targetInstanceId){
            message.error('Clone wordpress failed!');
            return;
          }
          //end - create temporary domain and clone this domain to it
          try{
              // begin - lặp lại delete All Post cho đến khi server trả về "There is no post to delete" để chắc chắn tất cả media đã được xóa nếu không sẽ bị lỗi ở giai đoạn tạo file backup
              let deletePostResult : any;
              while(deletePostResult != "There is no post to delete"){
                let res = await ApiService.post('/Domains/deleteAllPostWordpress', { instanceId: targetInstanceId, infoEncoded: state });
                deletePostResult = res.data.result;
              }
              message.success('All post is deleted!')

              // begin - lặp lại delete All Media cho đến khi server trả về "There is no media to delete" để chắc chắn tất cả media đã được xóa nếu không sẽ bị lỗi ở giai đoạn tạo file backup
              let deleteMediaResult : any;
              while(deleteMediaResult != "There is no media to delete"){
                let res = await ApiService.post('/Domains/deleteAllMediaWordpress', {instanceId: targetInstanceId, infoEncoded: state });
                deleteMediaResult = res.data.result;
              }
              message.success('All media is deleted!')
          }catch(err){
               // begin - lặp lại delete All Post cho đến khi server trả về "There is no post to delete" để chắc chắn tất cả media đã được xóa nếu không sẽ bị lỗi ở giai đoạn tạo file backup
               let deletePostResult : any;
               while(deletePostResult != "There is no post to delete"){
                 let res = await ApiService.post('/Domains/deleteAllPostWordpress', { instanceId: targetInstanceId, infoEncoded: state });
                 deletePostResult = res.data.result;
               }
               message.success('All post is deleted!')
              // begin - lặp lại delete All Media cho đến khi server trả về "There is no media to delete" để chắc chắn tất cả media đã được xóa nếu không sẽ bị lỗi ở giai đoạn tạo file backup
              let deleteMediaResult : any;
              while(deleteMediaResult != "There is no media to delete"){
                let res = await ApiService.post('/Domains/deleteAllMediaWordpress', {instanceId: targetInstanceId, infoEncoded: state });
                deleteMediaResult = res.data.result;
              }
              message.success('All media is deleted!')

          }
         
           
        
          let backupFileName: string = '';
          await ApiService.post('/Domains/backupWordpress', {instanceId: targetInstanceId, infoEncoded: state }).then(async res => {
              backupFileName = res.data.result.backupFilename;
              message.success('Bakup file is created!')
          });
          if(backupFileName){
            const body = {
              domainName: targetDomainName,
              userName : targetDomainName + '_subftp',
              password : 'changeme1Q@123XYZ',
              path : '/',
              backupFileName,
              infoEncoded: state
            }
            await ApiService.post('/Domains/createFTPSubAccountAndUploadBackupFile', body).then(async res => {
                message.success('Upload backup file to Cloud Server successfully!')
                const file = res.data.result;
                const fileBackupInfo = {
                  name: file.name,
                  description: '',
                  fileId: file.id,
                  fileName: file.name,
                  fileSize: file.size,
                  fileCreateTime: file.createTime,
                  backupFromDomain: domainName
                }
                await ApiService.post('/BackupTemplates/', fileBackupInfo).then(async res => {
                  await getBackupTemplateList(domainName, {where:{backupFromDomain: domainName}});
                })
            });
          }

          await ApiService.post('/Domains/deleteDomain', { id: targetDomainId, infoEncoded: state })
         
        } catch (err) {
          console.log(err);
          message.error('Error: ' + err);
        }
      },
      onCancel() {
      },
    });
  };
  const handleInstallWordpress = async (domainName: string, selectedPlugins: UploadedPlugin[], selectedThemes: UploadedTheme[]) => {
    confirm({
      title: 'Are you sure to install wordpress on this domain?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {

          let instanceId: number;
          await ApiService.post('/Domains/installWordpress', { domainName, infoEncoded: state }).then(async res => {
            if (res.status === 200) {
              instanceId = res.data.result["instance-id"];
            }
            // Disable comment - Not Allow people to submit comments on new post.
            await updateOptionWordpessInstance(instanceId, 'default_comment_status', 'closed');
            if (blogname) {
              await updateOptionWordpessInstance(instanceId, 'blogname', blogname);
            }
            // Install selected theme
            if (selectedThemes.length) {
              await ApiService.post('/Domains/installThemeWP', { id: instanceId, themeId: Number.parseInt(selectedThemes[0].id), themeVersion: selectedThemes[0].version, infoEncoded: state }).then(async res => {
                message.success('install theme "' + selectedThemes[0].name + '" successfully!');

              })
              await ApiService.post('/Domains/activateThemeWP', { id: instanceId, name: selectedThemes[0].slug, infoEncoded: state }).then(res => {

              })
            }
            // Install selected plugin
            if (selectedPlugins.length) {
              for (const pluginItem of selectedPlugins) {
                await ApiService.post('/Domains/installPluginWP', { id: instanceId, pluginId: Number.parseInt(pluginItem.id), pluginVersion: pluginItem.version, infoEncoded: state }).then(async res => {
                  message.success('install plugin "' + pluginItem.name + '" successfully!');

                })
              }
            }
            await clearCache(instanceId);

            await fetchData().then(res => {
              message.success('Wordpress site is installed!')
            });
            // Auto register WPML on websites
            const siteUrl = 'https://' + domainName;
            await ApiService.post('/Domains/wpmlAddSite', { siteUrl }).then(res => {
              console.log('register WPML successfully! ', res)
            })

          })


          setOpen(false);
        } catch (err) {
          console.log(err);
          message.error('Error: ' + err);
        }
      },
      onCancel() {
      },
    });
  };
  const handleInstallWordpress_WithoutConfirm = async (domainName: string, selectedPlugins: UploadedPlugin[], selectedThemes: UploadedTheme[]) => {
    try {
      let instanceId: number;
      await ApiService.post('/Domains/installWordpress', { domainName, infoEncoded: state }).then(async res => {
        message.success('install core wordpress successfully!!');

        if (res.status === 200) {
          instanceId = res.data.result["instance-id"];
        }
        // Disable comment - Not Allow people to submit comments on new post.
        await updateOptionWordpessInstance(instanceId, 'default_comment_status', 'closed');
        if (blogname) {
          await updateOptionWordpessInstance(instanceId, 'blogname', blogname);
        }

        // Install selected theme
        if (selectedThemes.length) {
          await ApiService.post('/Domains/installThemeWP', { id: instanceId, themeId: Number.parseInt(selectedThemes[0].id), themeVersion: selectedThemes[0].version, infoEncoded: state }).then(async res => {
            message.success('install theme "' + selectedThemes[0].name + '" successfully!');


          })
          await ApiService.post('/Domains/activateThemeWP', { id: instanceId, name: selectedThemes[0].slug, infoEncoded: state }).then(res => {


          })
        }
        // Install selected plugin
        if (selectedPlugins.length) {
          for (const pluginItem of selectedPlugins) {
            await ApiService.post('/Domains/installPluginWP', { id: instanceId, pluginId: Number.parseInt(pluginItem.id), pluginVersion: pluginItem.version, infoEncoded: state }).then(async res => {
              message.success('install plugin "' + pluginItem.name + '"  successfully!');


            })
          }
        }
        await clearCache(instanceId);

        await fetchData().then(res => {
          message.success('Wordpress site is created!')
        });
      })
      // Auto register WPML on websites
      const siteUrl = 'https://' + domainName;
      await ApiService.post('/Domains/wpmlAddSite', { siteUrl }).then(res => {
        console.log('register WPML successfully! ', res)
      })



    } catch (err) {
      console.log(err);
      message.error('Error: ' + err);
    }
  };
  const countWordpressInstance = (id) => {
    const wpInstances = listWordpress.filter(
      (instance: Record<string, any>) => instance.mainDomainId === id
    );
    return wpInstances.length;
  }

  const handleDeletePluginWP = (name: string) => {
    confirm({
      title: `Are you sure to delete plugin ${name} of WP ?`,
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {
          setLoadingState(true);
          const result = await ApiService.post('/Domains/deletePluginWP', {
            id: pluginsAndThemes.id,
            name,
            infoEncoded: state
          });
          console.log('result', result);
          await clearCache(pluginsAndThemes.id);
          await getPluginsThemesWPInstance(pluginsAndThemes.id).then(res => {
            message.success('Delete plugin successfully.');
            setLoadingState(false);
          })
        } catch (error) {
          setLoadingState(false);
          message.error(`Error: ${error}`);
        }
      },
      onCancel() {
      },
    });
  }

  const handleDeleteThemeWP = (name: string) => {
    confirm({
      title: `Are you sure to delete theme ${name} of WP ?`,
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {
          const result = await ApiService.post('/Domains/deleteThemeWP', {
            id: pluginsAndThemes.id,
            name,
            infoEncoded: state
          });
          console.log('result', result);
          await clearCache(pluginsAndThemes.id);
          await getPluginsThemesWPInstance(pluginsAndThemes.id).then(res => {
            message.success('Delete theme successfully.');
            setLoadingState(false);
          })
        } catch (error) {
          message.error(`Error: ${error}`);
        }
      },
      onCancel() {
      },
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  //Begin - Create Domain
  const handleCreateDomain = async (domainName) => {
    if (!domainName) {
      message.warning('Please choose domain!');
      return false;
    }
    const selectedDomain = listDomain.find(item => item.domain === domainName)
    if (!selectedDomain || !selectedDomain?.goDaddyId) {
      message.warning('Domain detail not found');
      return false;
    }
    const listDomainName = listDomains.map(e => e.name);
    if (listDomainName.includes(domainName)) {
      message.warning('This domain is already created! Please choose another domain.');
      return false;
    }
    let body = {
      "name": domainName,
      "hosting_type": "virtual",
      "hosting_settings": {
        "ftp_login": domainName + "_ftp",
        "ftp_password": "changeme1Q@123XYZ"
      }
    }
    try {
      await ApiService.post('/Domains/createDomain', { body, infoEncoded: state }).then(async res => {
        message.success('create domain "' + domainName + '" successfully!');

        // Create zone in cloudflare
      let zoneInfo: any;
      let name_servers: any;
      await ApiService.post('/Domains/createZone',{name: domainName}).then(async res => {
        zoneInfo = res.data.result;
        if(zoneInfo){
          name_servers = zoneInfo.name_servers;
          await ApiService.post('/Domains/updateDomainDetail_GoDaddy',{
            name: domainName,
            goDaddyId: selectedDomain.goDaddyId,
            body: {nameServers: name_servers}}).then(res =>{
          })
        }

      })
      // END - Create zone in cloudflare

      // BEGIN - Create DNS Record for domain
        const zoneId = await getZoneIdByDomainName(domainName);
        await ApiService.post('/Domains/changeZoneSslSetting',{zoneId,value:"full"}).then(res =>{
        })
        const listSubdoms = selectedSubDomains.map(e => e.prefix);
        const list_subdomain = selectedSubDomains.map(e => e.name);
        const content = vpsIP;
        const getGatewayAddress = () => "161.35.246.214";
        const getPromoAddress = () => "134.209.90.91";
        const getStagingAddress = () => "134.209.81.219";
        await Promise.all([
         handleCreateListSubDomain(listSubdoms, domainName, 'httpdocs'),
         handleCreateDNSRecord(zoneId, "A", domainName, content, true),
         handleCreateDNSRecord(zoneId, "CNAME", "www." + domainName, domainName, true),
         handleCreateDNSRecord(zoneId, "A", "mail." + domainName, content, false),
         handleCreateDNSRecord(zoneId, "A", "webmail." + domainName, content, false),

         handleCreateDNSRecord(zoneId, "A", "gateway." + domainName, getGatewayAddress(), true),
         handleCreateDNSRecord(zoneId, "A", "promo." + domainName, getPromoAddress(), true),
         handleCreateDNSRecord(zoneId, "A", "staging." + domainName, getStagingAddress(), true),

         handleCreateDNSRecord(zoneId, "CNAME", "_domainconnect." + domainName, "_domainconnect.gd.domaincontrol.com", true),
         handleCreateDNSRecord(zoneId, "MX", domainName, "mail." + domainName, false),
         handleCreateDNSRecord(zoneId, "TXT", "_dmarc." + domainName, "v=DMARC1; p=none", false),
         handleCreateDNSRecord(zoneId, "TXT", domainName, "v=spf1 +a +mx +a:mail."+ domainName + " ~all", false),

         handleCreateListDNSRecord(zoneId, "A", list_subdomain, content, true),
      ]);

      // END - Create DNS Record for domain

      })
      return true;
    } catch (err) {
      console.log(err);
      message.error('Error: ' + err);
      return false;
    }

  }
  const handleCreateListSubDomain = async (list_subdomain: string[], domain: string, www_root: string) => {
    try {
      let res = await ApiService.post('/Domains/createListSubdomain', { list_subdomain, domain, www_root, infoEncoded: state });
      if (res) {
        message.success('Create subdomains successfully.', 5);
      }
    } catch (err) {
      message.error(err, 10);
      console.log(err);
    }

  }
  const handleCreateDNSRecord = async (zoneId: string, type: string, name: string, content: string, proxied: boolean) => {
    try {
      const res = await ApiService.post('/Domains/createDNSRecord', { zoneId, type, name, content, proxied });
      return res;
    } catch (err) {
      message.error(err, 10);
    }
  }
  const handleCreateListDNSRecord = async (zoneId: string, type: string, list_subdomain: string[], content: string, proxied: boolean) => {
    let result: any = [];
    for (const name of list_subdomain) {
      const res = await handleCreateDNSRecord(zoneId, type, name, content, proxied);
      result.push(res);
    }
    return result;
  }
  const getZoneIdByDomainName = async (name: string) => {
    try {
      const res = await ApiService.getWithParams('/Domains/getZoneDetailByName', { name });
      return res?.data?.result[0]?.id;
    } catch (err) {
      message.error(err, 10);
      console.error(err);
      return '';
    }
  }
  const handleChangeDomain = (value: string) => {
    const  toProperCase = (s:string) =>
    {
      return s.toLowerCase().replace(/^(.)|\s(.)/g,
              function($1) { return $1.toUpperCase(); });
    }
    const text = value.slice(0, value.indexOf(".com"));
    setBlogname(()=>toProperCase(text));
    setDomain(value);
    const langs = ['en', 'es', 'it', 'fr', 'ch', 'pt'];
    let subDomains: SubDomain[] = langs.map((e, index) => {
      let temp = { id: index + 1, name: e + '.' + value, prefix: e };
      return temp;
    });
    setListSubDomains(subDomains);
  };

  //End - Create Domain



  const getPluginsThemesWPInstance = async (pleskId: number) => {
    const result = await ApiService.getWithParams('/Domains/getPluginWPInstances', {
      infoEncoded: state,
      wpId: pleskId
    });
    if (result?.data?.result) {
      setPluginsAndThemes(result.data.result);
    }
  }

  const onExpandRow = async (expanded: boolean, record: DomainPlesk): Promise<void> => {
    // when click + button to expand content of plesk
    // tabs: info, plugins, themes
    if (!expanded) {
      return;
    }
    const wp = listWordpress.find(wp => wp.mainDomainId === record?.id);
    const fetchedBefore = pluginsAndThemes?.id === wp?.id;
    if (wp?.id && !fetchedBefore) {
      getPluginsThemesWPInstance(wp.id);
    }
  }

  // active Plugin
  const activePlugin = async (name: string, index: number): Promise<void> => {
    setLoadingState(true);
    try {
      const result = await ApiService.post('/Domains/activatePluginWP', {
        id: pluginsAndThemes.id,
        name,
        infoEncoded: state,
      });
      if (result?.data?.result?.code === 0) {
        const currentPlugins = [...pluginsAndThemes.plugins];
        currentPlugins[index].status = 'active';
        const updateData = {
          ...pluginsAndThemes,
          plugins: currentPlugins,
        } as PluginsThemes;
        setPluginsAndThemes(updateData);
        setLoadingState(false);
        message.success('Activate plugin successfully.');
      }
    } catch (error) {
      message.error(`Error: ${error}`);
      setLoadingState(false);
    }
  };

  // deactive Plugin
  const deactivePlugin = async (name: string, index: number): Promise<void> => {
    setLoadingState(true);
    try {
      const result = await ApiService.post('/Domains/deactivatePluginWP', {
        id: pluginsAndThemes.id,
        name,
        infoEncoded: state
      });
      console.log('result', result);
      if (result?.data?.result?.code === 0) {
        const currentPlugins = [...pluginsAndThemes.plugins];
        currentPlugins[index].status = 'inactive';
        const updateData = {
          ...pluginsAndThemes,
          plugins: currentPlugins,
        } as PluginsThemes;
        setPluginsAndThemes(updateData);
        setLoadingState(false);
        message.success('Deactivate plugin successfully.');
      }
    } catch (error) {
      setLoadingState(false);
      message.error(`Error: ${error}`);
    }
  };

  const clearCache = async (instanceId: number): Promise<void> => {
    try {
      const { data } = await ApiService.post('/Domains/clearCache', { instanceId, infoEncoded: state });
      if (data?.result?.code === 0) {
        setLoadingState(false);
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  // active Theme
  const activeTheme = async (name: string, index: number): Promise<void> => {
    try {
      setLoadingState(true);
      const result = await ApiService.post('/Domains/activateThemeWP', {
        id: pluginsAndThemes.id,
        name,
        infoEncoded: state
      });
      await clearCache(pluginsAndThemes.id);
      await getPluginsThemesWPInstance(pluginsAndThemes.id).then(res => {
        message.success('Activate theme successfully!')
      });

    } catch (error) {
      message.error(`Error: ${error}`);
    }
  };

  const handleAllowComment = async (checked: boolean, instanceId: number) => {
    setAllowCommentStatus(checked);
    const optionName = "default_comment_status";
    let optionValue = checked ? "open" : "closed";
    await updateOptionWordpessInstance(instanceId, optionName, optionValue);

  }
  const updateOptionWordpessInstance = async (instanceId: number, optionName: string, optionValue: string) => {
    try {
      await ApiService.post('/Domains/updateOptionWordpressInstance', { instanceId, optionName, optionValue, infoEncoded: state });
    } catch (err) {
      message.error(err);
    }
  }
  const getAllowCommentStatusWordpressInstance = async (instanceId: string) => {
    console.log("instanceId", instanceId)
    try {
      const optionName: string = "default_comment_status";
      const res = await ApiService.post('/Domains/getValueOptionWordpressInstance', { instanceId, optionName, infoEncoded: state });
      if (res?.data?.result === 'open') {
        return true;
      } else if (res?.data?.result === 'closed') {
        return false;
      }
      return false;
    } catch (err) {
      message.error(err);
      console.log(err);
      return false;
    }
  }

  const handleCancelPleskThemesAnPlugins = () => {
    setOpenFleskThemesAndPluginsModal(false);
  }



  // Filter Search

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DomainPlesk> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<DomainPlesk> = [
    {
      title: 'Domain Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      // render: (text) => <a href={"https://"+text} target="_blank">{text}</a>,
    },
    {
      title: 'Hosting Type',
      dataIndex: 'hosting_type',
      key: 'hosting_type',
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      sorter: (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime(),
    },
    {
      title: 'Base Domain Id',
      dataIndex: 'base_domain_id',
      key: 'base_domain_id',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button danger onClick={() => handleDeleteDomain(record)}>Delete</Button>
          {countWordpressInstance(record.id) === 0 && <Button type="primary" onClick={() => showModal(record)}>Install Wordpress</Button>}
        </Space>
      ),
    },
  ];

  // START: Plesk Domains Status
  const [pleskStatusModalState, setPleskStatusModalState] = useState<boolean>(false);

  const viewDomainsStatus = (): void => {
    setPleskStatusModalState(true);
  };

  // END: Plesk Domains Status

  // START : PLESK PLUGINS AND THEMES
  const rowSelectionWordpressSites = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: WordpressSite[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedWordpressSites(selectedRows);
    }
  };
  const columnsWordpressInstance: ColumnsType<WordpressSite> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'URL',
      dataIndex: 'siteUrl',
      key: 'siteUrl',
    },
  ];

  const handleInstallThemesAndPlugins = async (themes: UploadedTheme[],plugins: UploadedPlugin[], websites: WordpressSite[]) => {
    let result : any[] = [];
    if(!websites.length){
      message.error('Please choose websites.');
      return;
    }
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {
          if(themes.length){
            for(const website of websites){
              const body = {
                id : website.id,
                list : selectedThemes,
                infoEncoded : state
              }
               await ApiService.post('/Domains/installListThemePlesk',body).then(res=>{
                  console.log('install themes',res);
                  result.push(res);
                  ApiService.post('/Domains/clearCache',{instanceId: website.id, infoEncoded : state});
               })
            }
            message.success('Install themes successfully!',5);
          }
          if(plugins.length){
            for(const website of websites){
              const body = {
                id : website.id,
                list : selectedPlugins,
                infoEncoded : state
              }
               await ApiService.post('/Domains/installAndActivateListPluginPlesk',body).then(res=>{
                  console.log('install plugins ',res);
                  result.push(res);
                  ApiService.post('/Domains/clearCache',{instanceId: website.id, infoEncoded : state});

               })
            }
              message.success('Install plugins successfully!',5);
          }
          setOpenListWordpressModal(false);
        } catch (err) {
          console.log(err);
          message.error('Error: ' + err);
          setOpenListWordpressModal(false);

        }
      },
      onCancel() {
      },
    });


  }
  const handleUninstallThemesAndPlugins = async (themes: UploadedTheme[],plugins: UploadedPlugin[], websites: WordpressSite[]) => {
    let result : any[] = [];
    if(!websites.length){
      message.error('Please choose websites.');
      return;
    }
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {
          if(themes.length){
            const listThemeName = themes.map(theme => theme.slug)
            for(const website of websites){
              const body = {
                id : website.id,
                list : listThemeName,
                infoEncoded: state

              }
               await ApiService.post('/Domains/deleteListThemeWP',body).then(res=>{
                  console.log('Uninstall themes',res);
                  result.push(res);
                ApiService.post('/Domains/clearCache',{instanceId: website.id, infoEncoded: state});
               })
            }
            message.success('Uninstall themes successfully!',5);
          }
          if(plugins.length){
            const listPluginName = plugins.map(plugin => plugin.slug)
            for(const website of websites){
              const body = {
                id : website.id,
                list : listPluginName,
                infoEncoded : state
              }
               await ApiService.post('/Domains/deleteListPluginWP',body).then(res=>{
                  console.log('uninstall plugins ',res);
                  result.push(res);
                  ApiService.post('/Domains/clearCache',{instanceId: website.id, infoEncoded: state});
               })
            }
              message.success('Uninstall plugins successfully!',5);
          }
          setOpenListWordpressModal(false);

        } catch (err) {
          console.log(err);
          message.error('Error: ' + err);
          setOpenListWordpressModal(false);


        }
      },
      onCancel() {
      },
    });


  }
  // END: PLESK PLUGINS AND THEMES
  return (
    <>
      <div style={{ margin: '5px' }}>
        <Button type="primary" onClick={() => showCreateWebsiteModal()}>Create Website</Button>
        <Button type="default" onClick={() => viewDomainsStatus()}>View Domains Status</Button>
        <Button type="default" onClick={() => setOpenFleskThemesAndPluginsModal(true)}>Plesk Themes and Plugins</Button>
      </div>
      <Spin tip="Loading..." size="large" spinning={loadingData}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={listDomains}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30'],
          }}
          rowClassName="editable-row"
          expandable={{
            expandedRowRender: (record) => (
              <PleskDetails
                domain={record}
                listWordpress={listWordpress}
                listPlugins={pluginsAndThemes.plugins}
                listThemes={pluginsAndThemes.themes}
                listSubDomains={allDomains.filter(e => e.base_domain_id === record.id)}
                allowCommentStatus={getAllowCommentStatusWordpressInstance}
                handleAllowComment={handleAllowComment}
                handleDeleteWordpress={handleDeleteWordpress}
                handleDeletePlugin={handleDeletePluginWP}
                handleDeleteThemeWP={handleDeleteThemeWP}
                handleCreateBackupFile={handleCreateBackupFile}
                handleDeleteSubDomain={handleDeleteDomain}
                handleAddSubDomain={showModalAddSubDomain}
                activePlugin={activePlugin}
                deactivePlugin={deactivePlugin}
                activeTheme={activeTheme}
                loadingState={loadingState}
                backupTemplateList={backupTemplateList}
                restoreTemplateList={restoreTemplateList}
                loadingBackupTemplateList={loadingBackupTemplateList}
                getBackupTemplateList={getBackupTemplateList}
              />
            ),
            rowExpandable: record => {
              const wpInstances = listWordpress.filter(
                (instance: Record<string, any>) => instance.mainDomainId === record.id
              );
              return wpInstances.length > 0;
            },
          }}
          onExpand={onExpandRow}
        />
      </Spin>
      <Modal
        open={open}
        title="Install Wordpress"
        onOk={() => handleOk(domainName, selectedPlugins, selectedThemes)}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={() => handleOk(domainName, selectedPlugins, selectedThemes)}>
            Install
          </Button>,
        ]}
        width={1000}
        style={{ top: 20 }}
      >

        <Title level={4}>Website Name:</Title>
        <Form>
          <Input value={blogname} onChange={e => setBlogname(e.target.value)} />
        </Form>
        <p></p>
        <Title level={4}>Plugins:</Title>
        <Spin tip="Loading..." size="large" spinning={loadingPlugins}>
          <Table
            rowKey={"id" && "version"}
            rowSelection={{
              type: 'checkbox',
              ...rowSelectionUploadedPlugins,
            }}
            columns={columnsUploadedPlugins}
            dataSource={listUploadedPlugins}
            pagination={{ defaultPageSize: 10 }}
            rowClassName="editable-row"
          />
        </Spin>

        <Title level={4}>Themes:</Title>
        <Spin tip="Loading..." size="large" spinning={loadingThemes}>
          <Table
            rowKey={"id" && "version"}
            rowSelection={{
              type: 'radio',
              ...rowSelectionUploadedThemes,
            }}
            columns={columnsUploadedThemes}
            dataSource={listUploadedThemes}
            pagination={{ defaultPageSize: 10 }}
            rowClassName="editable-row"
          />
        </Spin>
      </Modal>
      <Modal
        open={openCreateWebsiteModal}
        title="Create Website"
        onOk={() => handleCreateWebsite(domain, selectedPlugins, selectedThemes)}
        onCancel={handleCancelCreateWebsite}
        footer={[
          <Button key="back" onClick={handleCancelCreateWebsite}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={() => handleCreateWebsite(domain, selectedPlugins, selectedThemes)}>
            Create
          </Button>,
        ]}
        width={1000}
        style={{ top: 20 }}
      >
        <Title level={4}>Domain Name:</Title>
        <Select
          showSearch
          defaultValue=""
          style={{ width: 300 }}
          onChange={handleChangeDomain}
        >
          <Option value='' key={-1}>--Choose--</Option>
          {listDomain.map(item => {
            return (
              <Option value={item.domain} key={item.domainId}>{item.domain}</Option>
            );
          })}
        </Select>
        <p></p>
        <Title level={4}>Website Name:</Title>
        <Form>
          <Input value={blogname} onChange={e => setBlogname(e.target.value)} />
        </Form>
        <p></p>

        <Title level={4}>SubDomains:</Title>
        <Table
          rowKey="id"
          rowSelection={{
            type: 'checkbox',
            ...rowSelectionSubDomains,
          }}
          columns={columnsSubDomains}
          dataSource={listSubDomains}
          rowClassName="editable-row"
        />
        <Title level={4}>Plugins:</Title>
        <Spin tip="Loading..." size="large" spinning={loadingPlugins}>
          <Table
            rowKey={"id" && "version"}
            rowSelection={{
              type: 'checkbox',
              ...rowSelectionUploadedPlugins,
            }}
            columns={columnsUploadedPlugins}
            dataSource={listUploadedPlugins}
            pagination={{ defaultPageSize: 10 }}
            rowClassName="editable-row"
          />
        </Spin>

        <Title level={4}>Theme:</Title>
        <Spin tip="Loading..." size="large" spinning={loadingThemes}>
          <Table
            rowKey={"id" && "version"}
            rowSelection={{
              type: 'radio',
              ...rowSelectionUploadedThemes,
            }}
            columns={columnsUploadedThemes}
            dataSource={listUploadedThemes}
            pagination={{ defaultPageSize: 10 }}
            rowClassName="editable-row"
          />
        </Spin>
      </Modal>
      <Modal
        destroyOnClose
        open={openAddSubDomainModal}
        title="Add Subdomain"
        onOk={() => handleAddSubDomains(selectedSubDomains)}
        onCancel={handleCancelAddSubdomain}
        footer={[
          <Button key="back" onClick={handleCancelAddSubdomain}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={() => handleAddSubDomains(selectedSubDomains)}>
            Create
          </Button>,
        ]}
        width={1000}
        style={{ top: 20 }}
      >
        <Table
          rowKey="id"
          rowSelection={{
            type: 'checkbox',
            ...rowSelectionSubDomains,
          }}
          columns={columnsSubDomains}
          dataSource={listSubDomains}
          rowClassName="editable-row"
        />
      </Modal>
      <Modal
        destroyOnClose
        open={openFleskThemesAndPluginsModal}
        title="Plesk Plugins And Themes"
        onCancel={handleCancelPleskThemesAnPlugins}
        onOk={handleCancelPleskThemesAnPlugins}
        width={1000}
        style={{ top: 20 }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Plugins" key="1">
            <Button type="primary" onClick={()=> setOpenListWordpressModal(true)} disabled={selectedPlugins.length?false:true}>Install / Uninstall</Button>
            <p></p>
            <Spin tip="Loading..." size="large" spinning={loadingPlugins}>
              <Table
                rowKey={"id" && "version"}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelectionUploadedPlugins,
                }}
                columns={columnsUploadedPlugins}
                dataSource={listUploadedPlugins}
                pagination={{ defaultPageSize: 10 }}
                rowClassName="editable-row"
              />
            </Spin>
          </TabPane>
          <TabPane tab="Themes" key="2">
            <Button type="primary" onClick={()=> setOpenListWordpressModal(true)} disabled={selectedThemes.length?false:true}>Install / Uninstall</Button>
            <p></p>
            <Spin tip="Loading..." size="large" spinning={loadingThemes}>
              <Table
                rowKey={"id" && "version"}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelectionUploadedThemes,
                }}
                columns={columnsUploadedThemes}
                dataSource={listUploadedThemes}
                pagination={{ defaultPageSize: 10 }}
                rowClassName="editable-row"
              />
            </Spin>
          </TabPane>
        </Tabs>
      </Modal>

      {/* Modal Plesk Domain status */}
      <Modal
        destroyOnClose
        open={pleskStatusModalState}
        title="Plesk Domains Status"
        onCancel={() => setPleskStatusModalState(false)}
        onOk={() => setPleskStatusModalState(false)}
        width={1000}
        style={{ top: 20 }}
      >
        <PleskStatusModal state={state} />
      </Modal>
      {/* Modal Select Websites */}
      <Modal
              destroyOnClose
              open={openListWordpressModal}
              title="Select websites"
              onCancel={handleCancelListWordpressModal}
              footer={[
                <Button key="back" onClick={handleCancelListWordpressModal}>
                  Cancel
                </Button>,
                <Button key="install" type="primary" loading={loading} onClick={() => handleInstallThemesAndPlugins(selectedThemes, selectedPlugins, selectedWordpressSites)}>
                  Install
                </Button>,
                <Button key="uninstall" danger type="primary" loading={loading} onClick={() => handleUninstallThemesAndPlugins(selectedThemes, selectedPlugins, selectedWordpressSites)}>
                  Uninstall
                </Button>,
              ]}
              width={700}
              style={{ top: 20 }}
            >
              <Spin tip="Loading..." size="large" spinning={loadingData}>
                <Table
                  rowKey="id"
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelectionWordpressSites,
                  }}
                  columns={columnsWordpressInstance}
                  dataSource={listWordpress}
                  rowClassName="editable-row"
                />
              </Spin>
            </Modal>
    </>
  );
};
export default Plesks;
