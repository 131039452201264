import { base64Encode } from '@/util/handle-string';
import { Modal, Form, Input, Button } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

type Prop = {
  visible: boolean;
  visibleEvent: (visible: boolean) => void;
};

const PleskModal = (props: Prop) => {
  const navigate = useNavigate();

  const initalForm = {
    username: 'admin',
    password: 'iSo95*l66',
  };

  const handleModalOk = () => {};

  const handleModalCancel = () => {
    props.visibleEvent(false)
  };

  const onFinish = (values: Record<string, any>) => {
    const authenInfo: string = `Basic ${base64Encode(`${values.username}:${values.password}`)}`;
    navigate("/admin/plesks", { state: authenInfo });
  };

  useEffect(() => {}, []);

  return (
    <Modal
      title="Plesk account"
      getContainer={false}
      open={props.visible}
      onOk={handleModalOk}
      onCancel={handleModalCancel}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        initialValues={initalForm}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PleskModal;
