import React from 'react';
import { RouteObject, useParams } from 'react-router-dom';

import Login from './pages/Login/login';
import NotFoundPage from '@/pages/404';
import PublicLayout from '@/layouts/public-layout';
import UserLayout from '@/layouts/user-layout';
import Droplets from '@/pages/Droplets/droplets';
import WebsitesAndDomains from './pages/WebsitesAndDomains/websites-and-domains';
import Wordpress from './pages/Wordpress/wordpress';
import Plesks from './pages/Plesks/plesks';
import Settings from './pages/Settings/settings';
import DigitalOceans from './pages/DigitalOceans/DigitalOceans';
import ThemesAndPlugins from './pages/ThemesAndPlugins/themes-and-plugins';

// ví dụ trích param từ url
const PageWithId = () => {
  const params = useParams();
  return (
    <>
      <h1>POST DETAIL PAGE</h1>
    </>
  );
};

const routes: RouteObject[] = [
  {
    path: '/',
    element: <PublicLayout />,
    children: [{ index: true, element: <Login /> }],
  },
  {
    path: 'admin',
    element: <UserLayout />,
    children: [
      { index: true, element: <DigitalOceans /> },
      // { index: true, element: <CreateWebsite /> },
      {
        path: 'themes-and-plugins',
        element: <ThemesAndPlugins />
      },
      {
        path: 'websites-and-domains',
        element: <WebsitesAndDomains />
      },
      {
        path: 'wordpress',
        element: <Wordpress />
      },
      {
        path: 'droplets',
        element: <Droplets />,
      },
      {
        path: 'plesks',
        element: <Plesks />,
      },
      {
        path: 'settings',
        element: <Settings />,
      },
      {
        path: 'digital-oceans',
        element: <DigitalOceans />,
      },
    ],
  },

  { path: '*', element: <NotFoundPage /> },
];

export default routes;
